.staffTemplate {
	padding-top: 3rem;
	background-color: black;
	color: lightgray;
	padding-bottom: 2rem;
}


.staffTemplate h1 {
	width: 100%;
	display: block;
	text-align: center;
	margin: 1.5rem auto;
}

.staffProfile {
	display: flex;
	width: 75%;
	margin: auto;
	align-items: center;
	border: 0.2px solid #161616;
	padding: 2rem;
}

.staffImg {
	width: 50%;
}

img {
	width: 70%;
	display: block;
	margin: auto;
}

.staffDetails {
	width: 50%;
	line-height: 20px;
	padding-left: 1rem;
}

h2 {
	margin-bottom: 1.5rem;
}

.swapOrder {
	padding-right: 1rem;
}

@media only screen and (max-width: 964px) {
.staffProfile {
	display: flex;
	width: 85%;
	margin: auto;
	align-items: center;
	border: 0.2px solid #161616;
	padding: 2rem;
}	
}

@media only screen and (max-width: 838px) {
.staffProfile {
	display: flex;
	flex-direction: column;
	width: 85%;
	margin: auto;
	border: 0.2px solid #161616;
	padding: 2rem;
	text-align: center;
}

.swapOrder {
	order: 2;
}

.staffImg {
	width: 80%;
}

img {
	width: 70%;
	display: block;
	margin: auto;
}

.staffDetails {
	width: 80%;
	line-height: 20px;
	display: block;	
}

h2 {
	text-align: center;
	margin-bottom: 1rem;
}

}

@media only screen and (max-width: 614px) {
.staffProfile {
	display: flex;
	flex-direction: column;
	width: 85%;
	margin: auto;
	border: 0.2px solid #161616;
	padding: 0.5rem;
}

.swapOrder {
	order: 2;
}

.staffImg {
	width: 80%;
}

img {
	width: 85%;
	display: block;
	margin: auto;
}

.staffDetails {
	width: 80%;
	line-height: 20px;
}

h2 {
	text-align: center;
	margin-bottom: 1rem;
}

}