.mobileLookBook {
	width: 85%;
	margin: 15px auto;
	display: none;
}

.lbMImg {
	width: 100%;
	margin: 25px auto;
}

.lbMImg img {
	display: block;
	height: 320px;
	width: 270px;
	object-fit: cover;
	object-position: top;
	border: 6px solid white;
}

.pPricing {
	display: block;
	width: 100%;
	text-align: center;
}

.tpMainContainer {
	background-color: black;
	color: white;
	position: relative;
	background: black;
}

.lookBookMainContainer {
	background-color: black;
	color: white;
	padding-bottom: 20px;
}

.mIContainer {
	width: 80%;
	margin: auto;
	background-color: black;
	flex-direction: column;
	justify-content: center;
	align-items: center;	
}

.hideVisiblity {
	visibility: none;
}

.lbContainer {
	width: 80%;
	margin: auto;
}

.mobileTPImg {
	display: none;
}

.mainImage {
	width: 80%;
	margin: auto;
	height: 500px;
	padding-bottom: 30px;
}

.closeImg {
	letter-spacing: 1px;
	text-decoration: underline;
	margin: 30px auto;
	display: block;
	width: 100%;
	text-align: center;
	font-size: 0.8rem;
	cursor: pointer;
}

.mainImage img {
	width: 80%;
	height: 100%;
	display: block;
	object-fit: contain
}

.piercingPriceList {
	width: 70%;
	margin: 25px auto;
	border: 0.5px solid white;
}

.listContainer {
	display: flex;
	border-top: 1px white solid;
}

.pplTypeList, .pplCostList {
	width: 50%;
}

.pplCostList {
	border-left: 0.5px solid white; ;
}

.tableText {
	display: block;
	width: 100%;
	text-align: center;
	padding: 5px;
}

.pplHeader {
 	display: flex;
 	width: 100%;
 	border-bottom: 0.5px solid white;
}

.pplServiceType, .pplPrice {
	width: 50%;
}

.pplPrice {
	border-left: 1px solid black;
}

.pplHeadSection {
	padding: 5px;
	display: block;
	width: 100%;
	text-align: center;
	font-weight: bold;
	letter-spacing: 0.8px;
	background-color: white;
	color: black;
}

.hideContent {
	display: hide;
}

.showContent {
	display: initial;
}

.lookBookBanner {
	background-image: url("../assets/lookbookwallpaper.jpg");
	background-size: cover;
	background-position: center top;
	height: 500px;
	width: 100%;
}

.tatooBanner {
	background-image: url("../assets/tatooBanner.jpg");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	height: 450px;
	width: 100%;
	margin-bottom: 30px;
}

.pGallery {
	width: 80%;
	margin: auto;
	padding-bottom: 30px;
}

.pRow {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.tandpcontainer {
	width: 60%;
	display: flex;
	margin: 60px auto;
	justify-content: space-around;
}

.tatooImg {
	width: 50%;
	margin: 10px 30px;
	box-shadow: 10px 10px 10px gray;
}

.tatooImg img {
	width: 100%;
}

.imgContainer {
	margin: 10px 5px;
	transition: 1s all;
	cursor: pointer;
	width: 15%;
	flex-grow: 1;
	border: 6px solid white;
}

.lookBookIntro {
	width: 100%;
	margin: 15px auto;
	text-align: center;
}

.lookBookDesktopSection {
	margin: 30px auto;
	width: 85%;
	display: flex;
}

.showImgContainer {
	margin: auto;
	width: 40%;
	padding-top: 60px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;	
}

.hideImgContainer {
	display: none;
	margin: 40px auto;
	width: 40%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
}

.arrows {
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	width: 5%;
	color: gray;
	font-size: 1.5rem;
}

.closePimage {
	font-size: 1.2rem;
	display: block;
	width: 95%;
	margin: 10px auto;
	text-align: right;
}

.selectedImgContainer {
	width: 50%;
	margin: auto;
}

.selectedImg {
	width: 100%;
	margin: 10px auto;
	border: 10px white solid;
}

.slideShow {
	width: 90%;
	overflow: hidden;
}

.slider {
	transition: 0.5s all;
	width: 600%;
	display: flex;
	justify-content: space-between;
	/*increase by 16.6*/
}


.slider1 {
	transition: 0.5s all;
	width: 600%;
	display: flex;
	transform: translateX(-16.6%);	
}

.slider2 {
	transition: 0.5s all;
	width: 600%;
	display: flex;
	transform: translateX(-33.2%);	
}

.slider3 {
	transition: 0.5s all;
	width: 600%;
	display: flex;
	transform: translateX(-49.8%);	
}

.slider4 {
	transition: 0.5s all;
	width: 600%;
	display: flex;
	transform: translateX(-66.4%);	
}

.slider5 {
	transition: 0.5s all;
	width: 600%;
	display: flex;
	transform: translateX(-83%);	
}

.slider6 {
	transition: 0.5s all;
	width: 600%;
	display: flex;
	transform: translateX(-83%);	
}

.sliderFrame {
	margin: auto;
	width: 16%;
	display: flex;
	justify-content: space-around;
}

.slideShow img {
	width: 25%;
	margin: auto 10px;
	border: 6px solid white;
}

.lookBookMobile {
	width: 90%;
	margin: 10px auto;
	display: none;
}

.lookBookMobile img {
	width: 85%;
	margin: 10px auto;
}

.lookBookContainer {
	width: 85%;
	margin: 25px auto;
	display: flex;
	justify-content: space-around;
}

.lookBookContainer img {	
	width: 45%;
	box-shadow: 3px 3px 3px gray;
	margin: 5px;
}


.tatooGallery {
	width: 85%;
	margin: 20px auto;
}

.galleryImg {
	width: 85%;
	margin: 20px auto;
	display: flex;
	justify-content: space-around;
}

.galleryImg img {
	box-shadow: 10px 10px 10px gray;
	margin: auto 10px;
}

@media only screen and (max-width: 888px) {

.imgContainer {
	margin: 10px 5px;
	transition: 1s all;
	cursor: pointer;
	min-width: 30%;
	flex-grow: 1;
	border: 6px solid white;
}

.desktopImg {
	display: none;
}

.mobileTPImg {
	display: initial;
}

}


@media only screen and (max-width: 800px) {
	.lookBookBanner {
	background-image: url("../assets/lookbookwallpaper.jpg");
	background-size: cover;
	background-position: center top;
	height: 300px;
	width: 100%;
}
}

@media only screen and (max-width: 700px) {

.lookBookDesktopSection {
	display: none;
}

.mobileLookBook {
	display: initial;
}

.piercingPriceList {
	width: 90%;
	margin: 25px auto;
	border: 0.5px solid white;
}

}

@media only screen and (max-width: 536px) {

.tatooBanner {
	background-image: url("../assets/tatoo/tatooWallpaper2.jpg");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	height: 380px;
	width: 100%;
}

.lookBookBanner {
	background-image: url("../assets/lookbookwallpapermobile.jpg");
	background-size: cover;
	background-position: center;
	height: 400px;
	width: 100%;
}

.piercingPriceList {
	width: 100%;
	margin: 25px auto;
	border: 0.5px solid white;
}

.imgContainer {
	transition: 1s all;
	width: 45%;
	flex-grow: 1;
	border: 6px solid white;
}


.pGallery {
	width: 90%;
	margin: auto;
	padding-bottom: 30px;
}

}

@media only screen and (max-width: 390px) {

.lookBookDesktopSection {
	display: none;
}

.lookBookMobile {
	width: 90%;
	margin: 10px auto;
	display: initial;
}


.imgContainer {
	margin: 5px;
	transition: 1s all;
	width: 90%;
	flex-grow: 1;
	border: 6px solid white;
}

.pGallery {
	width: 90%;
	margin: auto;
	padding-bottom: 30px;
}


}