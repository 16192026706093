.ProductTemplate {
	min-width: 30%;
	padding: 1.5rem 1rem 0.5rem;
	margin: 1rem 2rem;
	text-align: center;
	position: relative;
	height: 500px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

}

.ProductTemplate img {
	width: 80%;
	margin: auto;
	margin-bottom: 1rem;
}

.ProductTemplate h2 {
	display: block;
	text-align: center;
	border-top: 1px solid #d1d1d1;
	width: 100%;
	padding-top: 0.5rem;
	text-transform: capitalize;
	min-height: 50px;
	font-size: 0.8rem;
}


.productDescriptionPrice {
	min-height: 75px;
}

.productPrice {
	font-size: 0.8rem;
	font-style: bold;
}

.ProductTemplate button {
	width: 100%;
	font-family: 'Teko', sans-serif;	
	padding: 0.2rem 2rem;
	background-color: black;
	color: white;
	font-size: 1.2rem;
	letter-spacing: 0.1rem;
	border: none;
	display: block;
	margin: 0.5rem auto;
	transition: 1s all;
}

.ProductTemplate button:hover {
		color: #B48B4C;
}


@media only screen and (max-width: 1090px) {
	.ProductTemplate {
	min-width: 30%;
	margin: 0.2rem 2rem;
	text-align: center;
	position: relative;
	height: 440px;
	display: flex;
	flex-direction: column;

}
}

@media only screen and (max-width: 1090px) {
	.ProductTemplate {
	min-width: 30%;
	margin: 0.2rem 2rem;
	text-align: center;
	position: relative;
	height: 480px;
	display: flex;
	flex-direction: column;

}
}

@media only screen and (max-width: 670px) {
	.ProductTemplate {
	min-width: 30%;
	margin: 0.2rem 2rem;
	text-align: center;
	position: relative;
	height: 450px;
	display: flex;
	flex-direction: column;

}
}

@media only screen and (max-width: 584px) {
	.ProductTemplate {
	min-width: 30%;
	margin: 0.2rem 2rem;
	text-align: center;
	position: relative;
	height: 580px;
	display: flex;
	flex-direction: column;

}
}

@media only screen and (max-width: 466px) {
	.ProductTemplate {
	min-width: 30%;
	margin: 0.2rem 2rem;
	text-align: center;
	position: relative;
	height: 510px;
	display: flex;
	flex-direction: column;

}
}

@media only screen and (max-width: 400px) {
	.ProductTemplate {
	min-width: 30%;
	margin: 0.2rem 2rem;
	text-align: center;
	position: relative;
	height: 440px;
	display: flex;
	flex-direction: column;

}
}