.beyondTheChair {
	background-image: url("../assets/btbcbanner.jpg");
	background-size: cover;
	background-position: center;
	height: 500px;
	width: 100%;
}

.galleryContainer {
	width: 100%;
	margin: 35px auto;
	display: flex;
	justify-content: space-between;
}

.mobileImg {
	display: none;
	width: 100%;
	margin: 10px auto;
}

.highlightImg {
	min-width: 24%;
	margin: auto 5px;
	width: 24%;
}

.btcCaption {
	width: 100%;
	text-align: center;
}

.textOne, .textTwo {
	margin: 30px auto;
	text-shadow: 4px 4px 4px black;
	color: white;
	display: block;
	font-family: 'Teko', sans-serif;
	font-size: 7rem;
	letter-spacing: 5px;
	opacity: 0;
}

.btcContainer img {
	border: 6px solid white;
}

.bytcHeading {
	text-align: center;
	color:  white;
}

.textOne {
	animation-name: arriveFromRight;
	animation-delay: 2s;
	animation-duration: 1s;
	animation-fill-mode: forwards;
}

.textTwo {
	animation-name: arriveFromLeft;
	animation-delay: 2s;
	animation-duration: 1s;
	animation-fill-mode: forwards;
}

.btcText {
	width: 80%;
	margin: 50px auto;
	display: flex;
	justify-content: space-around;

}

.articleContent {
	width: 75%;
	margin: 10px auto;
	text-align: center;
}

.articleImg {
	width: 75%;
	margin: 20px auto;
	display: flex;
	justify-content: space-between;
}

.articleSingleImg {
	width: 45%;
}

.articleSingleImg img {
	width: 100%;
}

.btcHeading {
	font-weight: bold;
	font-size: 0.9rem;
	text-transform: uppercase;
	letter-spacing: 1px;
	width: 100%;
	text-align: center;
	margin: 0.5px auto;
	padding-bottom: 15px;
	border-bottom: 1px solid #b5b5b5;;
}

.btc-info {
	text-align: justify;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 45%;
	margin: auto 10px;
}


.btcImg {
	margin: auto 10px;
	width: 45%;
}

.socialMedia {
	display: inline-block;
	width: 10%;
	margin: auto 15px;
}

.youTubeList {
	border-top: 1px solid #161616;
	width: 80%;
	margin: auto;
	position: relative;
}

.ytHeading {
	margin-top: 30px;
	width: 100%;
	text-align: center;
}

.videoContainer {
	width: 100%;
	margin: 10px auto;
	position: relative;
 	padding-top: 56.25%	
}

.videoDiv {
position: absolute;
  top: 0;
  left: 0;
}

.youTubeListTablet {
	width: 50%;
	margin: auto;
}

@keyframes arriveFromRight {
	from {transform: translateX(-150px); opacity: 0;}
	to {transform: translateY(0); opacity: 1;}
}

@keyframes arriveFromLeft {
	from {transform: translateX(150px); opacity: 0;}
	to {transform: translateY(0); opacity: 1;}
}


@media only screen and (max-width: 1054px) {
.btcText {
	width: 90%;
	margin: 60px auto;
	display: flex;
	justify-content: space-around;
	color: white;

}
}

@media only screen and (max-width: 836px) {
.btcText {
	width: 75%;
	margin: 60px auto;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	color: white;

}

.galleryContainer {
	width: 100%;
	margin: 5px auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.highlightImg {
	width: 46%;
	margin: 5px;
}

.text-right {
	order: 1;
}

.btc-info {
	text-align: justify;
	width: 80%;
	margin: auto;
}


.btcImg {
	margin: 20px auto;
	width: 80%;
}

}


@media only screen and (max-width: 836px) {
.btcText {
	width: 95%;
	margin: 40px auto;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	color: white;

}

.btc-info {
	text-align: justify;
	width: 80%;
	margin: auto;
}


.btcImg {
	margin: 20px auto;
	width: 80%;
}

.textOne, .textTwo {
	margin: 30px auto;
	text-shadow: 4px 4px 4px black;
	color: white;
	display: block;
	font-family: 'Teko', sans-serif;
	font-size: 4rem;
	letter-spacing: 5px;
	opacity: 0;
}


}

@media only screen and (max-width: 716px) {

.beyondTheChair {
	background-image: url("../assets/btbcbanner.jpg");
	background-size: cover;
	background-position: right;
	height: 420px;
	width: 100%;
}

}

@media only screen and (max-width: 456px) {
.btcText {
	width: 100%;
	margin: 25px auto;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	color: white;

}

.beyondTheChair {
	background-image: url("../assets/btbcbanner.jpg");
	background-size: cover;
	background-position: 85% 80%;
	height: 350px;
	width: 100%;
}

.galleryContainer {
	width: 100%;
	margin: 5px auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.highlightImg {
	min-width: 90%;
	margin: 15px auto;
}

.mobileImg {
	display: initial;
}

.textOne, .textTwo {
	margin: 10px auto;
	text-shadow: 4px 4px 4px black;
	color: white;
	display: block;
	font-family: 'Teko', sans-serif;
	font-size: 2.5rem;
	letter-spacing: 5px;
	opacity: 0;
}

.btc-info {
	text-align: justify;
	width: 80%;
	margin: auto;
}


.btcImg {
	margin: 20px auto;
	width: 80%;
}

}