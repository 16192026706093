* {
  padding: 0;
  box-sizing: border-box;
}

.sectionHeading {
	margin: 0.5rem auto;
	letter-spacing: 0.1rem;
}

a {
	color: white;
	font-size: 0.9rem;
}

p {
	margin: 0.5rem auto;
}

.servicesInfo {
	font-size: 0.9rem;
	line-height: 25px;
}

.mobileProductsCat img {
	width: 100%;
	height: 50vh;
}

.products-category {
	width: 100%;
	display: flex;
	height: 100vh;
}

.covid-notice {
	padding: 50px;
	width: 100%;
	height: 600px;
	height: 100vh;
	overflow: scroll;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10000000;
	background-color: white;
	color: black;
	text-align: center;
	line-height: 25px;
}

.aknoledgeNotice {
	width: auto;
	display: inline-block;
	padding: 10px 20px;
	background-color: black;
	color: white;
	margin: 15px auto;
	cursor: pointer;
}

.footer {
	width: 100%;
	color: white;
	padding: 1rem 0;
	text-align: center;
	height: auto;
	background-color: black;
	border-top: gray 0.5px solid;
}

.mobilePic {
	display: none;
}

.mobileProductsCat {
	display: none;
	background-color: black;
}

.mobile-hair-products {
	width: 100%;
	position: relative;
}

.mobile-beard-products {
	width: 100%;	
	position: relative;
}

.ListProducts {
	width: 90%;
	margin: 1rem auto;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}

.productLink {
	position: absolute;
	bottom: 0;
	width: 80%;
	left: 10%;
	border: white 1px solid;
	font-family: 'Teko', sans-serif;
	color: white;
	background-color: black;
	transition: 1s all;
}

.beard-products-selected {
	background-image: url("assets/beard-products.jpg");
	background-size: cover;
	background-position: 100% 80%;
	height: 100vh;
	filter: brightness(0%);
	animation-name: dimToLightHair;
	animation-delay: 1s;
	animation-duration: 2s;
	animation-fill-mode: forwards;				
	width: 50%;
}

.hair-products-selected {
	background-image: url("assets/hair-products.jpg");
	background-size: cover;
	background-position: 1% 80%;
	height: 100vh;	
	animation-name: dimToLightHair;
	filter: brightness(0%);	
	animation-delay: 2s;
	animation-duration: 2s;
	animation-fill-mode: forwards;			
	width: 50%;
	border-right: 0.5px #212121 solid;
}

.productBannerHeading {
	font-family: 'Teko', sans-serif;
	color: white;
	text-align: center;
	font-size: 3rem;
	letter-spacing: 0.2rem;
	width: 100%;
	display: block;
	text-align: center;
	margin: 30% auto 0;
	bottom: 60%;
	opacity: 0;
	animation-name: AppearFromTop;
	animation-duration: 2.5s;
	animation-delay: 1s;
	animation-fill-mode: forwards;	
}


.productBannerBtn {
	width: 50%;
	display: block;
	font-family: 'Teko', sans-serif;
	font-size: 1.5rem;
	letter-spacing: 0.1rem;
	color: white;
	text-align: center;
	background-color: black;
	border: 2px white solid;
	margin: 1% auto 0;
	padding: 0.4rem 3rem;
	opacity: 0;
	animation-name: AppearFromBottom;
	animation-duration: 3s;
	animation-delay: 1.5s;
	animation-fill-mode: forwards;	
	animation-timing-function: linear;
}

.sectionHeading {
	border-bottom: 1px solid white;
}

.hairRange {
	cursor: pointer;	
	position: absolute;
	width: 50%;
	left: 25%;
	opacity: 0;
	display: block;
	font-family: 'Teko', sans-serif;
	font-size: 1.1rem;
	font-weight: lighter;
	letter-spacing: 0.1rem;
	color: white;
	text-align: center;
	background-color: black;
	border: 2px white solid;
	padding: 0.4rem 3rem;
	animation-name: AppearFromBottom;
	animation-delay: 1.5s;
	animation-fill-mode: forwards;
	animation-duration: 1s;
	transition: 1s all;			
}

.beardRange {
	cursor: pointer;
	position: absolute;
	width: 50%;
	font-weight: lighter;	
	left: 25%;
	opacity: 0;
	display: block;
	font-family: 'Teko', sans-serif;
	font-size: 1.1rem;
	letter-spacing: 0.1rem;
	color: white;
	text-align: center;
	background-color: black;
	border: 2px white solid;
	padding: 0.4rem 3rem;
	animation-name: AppearFromBottom;
	animation-delay: 2.5s;
	animation-fill-mode: forwards;
	animation-duration: 1s;
	transition: 1s all;						
}


.mainHeading {
	text-align: center;
	display: block;
	width: 100%;
}

.beardRange:hover, .hairRange:hover, .productLink:hover, .activeNavLink {
	color: #B48B4C;
}

.hideContent {
	display: none;
}

.mainContent {
	margin-top: 3rem;
}

.btcContent {
	padding: 2rem 0;
	background-color: black;
	color: white;
	margin: auto;
}

@keyframes AppearFromBottom {
	from {opacity: 0; transform: translateY(380px);}
	to {opacity: 9; transform: translateY(520px);}	
}

@keyframes dimToLightHair {
	from {filter: brightness(0%);}
	to {filter: brightness(80%);}
}


@keyframes emergeDetails {
	from {opacity: 0; transform: translateY(-60%)}
	to {opacity: 1; transform: translateY(0%);}
}

@keyframes dimAndZoom {
	from {filter: brightness(0%);}
	to {filter: brightness(100%);}
}

@media only screen and (max-width: 1193px) {
.beard-products-selected {
	background-image: url("assets/beard-products.jpg");
	background-size: cover;
	background-position: contain;
	height: 100vh;
	filter: brightness(0%);
	animation-name: dimToLightHair;
	animation-delay: 1s;
	animation-duration: 2s;
	animation-fill-mode: forwards;				
	width: 50%;
}

.hair-products-selected {
	background-image: url("assets/hair-products.jpg");
	background-size: cover;
	background-position: contain;
	height: 100vh;	
	animation-name: dimToLightHair;
	filter: brightness(0%);	
	animation-delay: 2s;
	animation-duration: 2s;
	animation-fill-mode: forwards;			
	width: 50%;
	border-right: 0.1px #ededed solid;
}

.hairRange {
	cursor: pointer;	
	position: absolute;
	width: 65%;
	left: 17.5%;
	opacity: 0;
	display: block;
	font-family: 'Teko', sans-serif;
	font-size: 1.1rem;
	font-weight: lighter;
	letter-spacing: 0.1rem;
	color: white;
	text-align: center;
	background-color: black;
	border: 2px white solid;
	padding: 0.4rem 3rem;
	animation-name: AppearFromBottom;
	animation-delay: 1.5s;
	animation-fill-mode: forwards;
	animation-duration: 1s;
	transition: 1s all;			
}

.beardRange {
	cursor: pointer;
	position: absolute;
	width: 65%;
	font-weight: lighter;	
	left: 17.5%;
	opacity: 0;
	display: block;
	font-family: 'Teko', sans-serif;
	font-size: 1.1rem;
	letter-spacing: 0.1rem;
	color: white;
	text-align: center;
	background-color: black;
	border: 2px white solid;
	padding: 0.4rem 3rem;
	animation-name: AppearFromBottom;
	animation-delay: 2.5s;
	animation-fill-mode: forwards;
	animation-duration: 1s;
	transition: 1s all;						
}

}

@media only screen and (max-width: 1079px) {
.products-category {
	width: 100%;
	display: flex;
	height: 85vh;
}	
}

@media only screen and (max-width: 924px) {
.hairRange {
	cursor: pointer;	
	position: absolute;
	width: 80%;
	left: 10%;
	opacity: 0;
	display: block;
	font-family: 'Teko', sans-serif;
	font-size: 1.1rem;
	font-weight: lighter;
	letter-spacing: 0.1rem;
	color: white;
	text-align: center;
	background-color: black;
	border: 2px white solid;
	padding: 0.4rem 3rem;
	animation-name: AppearFromBottom;
	animation-delay: 1.5s;
	animation-fill-mode: forwards;
	animation-duration: 1s;
	transition: 1s all;			
}

.beardRange {
	cursor: pointer;
	position: absolute;
	width: 80%;
	font-weight: lighter;	
	left: 10%;
	opacity: 0;
	display: block;
	font-family: 'Teko', sans-serif;
	font-size: 1.1rem;
	letter-spacing: 0.1rem;
	color: white;
	text-align: center;
	background-color: black;
	border: 2px white solid;
	padding: 0.4rem 3rem;
	animation-name: AppearFromBottom;
	animation-delay: 2.5s;
	animation-fill-mode: forwards;
	animation-duration: 1s;
	transition: 1s all;						
}

.hair-products-selected {
	background-image: url("assets/hair-products.jpg");
	background-size: cover;
	background-position: 10% 80%;
	height: 100vh;	
	animation-name: dimToLightHair;
	filter: brightness(0%);	
	animation-delay: 2s;
	animation-duration: 2s;
	animation-fill-mode: forwards;			
	width: 50%;
	border-right: 0.1px #ededed solid;
}

.beard-products-selected {
	background-image: url("assets/beard-products.jpg");
	background-size: cover;
	background-position: 70% 80%;
	height: 100vh;
	filter: brightness(0%);
	animation-name: dimToLightHair;
	animation-delay: 1s;
	animation-duration: 2s;
	animation-fill-mode: forwards;				
	width: 50%;
}


}

@media only screen and (max-width: 868px) {
	.ListProducts {
	width: 90%;
	margin: 1rem auto;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}	
}

@media only screen and (max-width: 800px) {
.beard-products-selected {
	background-image: url("assets/beard-products.jpg");
	background-size: cover;
	background-position: 80% 10%;
	height: 100vh;
	filter: brightness(0%);
	animation-name: dimToLightHair;
	animation-delay: 1s;
	animation-duration: 2s;
	animation-fill-mode: forwards;				
	width: 50%;
}

.hair-products-selected {
	background-image: url("assets/hair-products.jpg");
	background-size: cover;
	background-position: 20% 10%;
	height: 100vh;	
	animation-name: dimToLightHair;
	filter: brightness(0%);	
	animation-delay: 2s;
	animation-duration: 2s;
	animation-fill-mode: forwards;			
	width: 50%;
	border-right: 0.1px #ededed solid;
}

.hairRange {
	cursor: pointer;	
	position: absolute;
	width: 80%;
	left: 10%;
	opacity: 0;
	display: block;
	font-family: 'Teko', sans-serif;
	font-size: 0.9rem;
	font-weight: lighter;
	letter-spacing: 0.1rem;
	color: white;
	text-align: center;
	background-color: black;
	border: 2px white solid;
	padding: 0.4rem 1rem;
	animation-name: AppearFromBottom;
	animation-delay: 1.5s;
	animation-fill-mode: forwards;
	animation-duration: 1s;
	transition: 1s all;			
}

.beardRange {
	cursor: pointer;
	position: absolute;
	width: 80%;
	font-weight: lighter;	
	left: 10%;
	opacity: 0;
	display: block;
	font-family: 'Teko', sans-serif;
	font-size: 0.9rem;
	letter-spacing: 0.1rem;
	color: white;
	text-align: center;
	background-color: black;
	border: 2px white solid;
	padding: 0.4rem 1rem;
	animation-name: AppearFromBottom;
	animation-delay: 2.5s;
	animation-fill-mode: forwards;
	animation-duration: 1s;
	transition: 1s all;						
}	
}

.contactTemplate {
	background-color: black;
	color: white;
	filter: brightness(0%);
	font-family: 'Teko', sans-serif;
	animation-name: dimAndZoom;
	animation-delay: 0.5s;
	animation-duration: 2s;
	animation-fill-mode: forwards;	
}

.contactDetailsTemplate {
	transform: translateY(-60%);
	opacity: 0;
	width: 100%;
	animation-name: emergeDetails;
	animation-duration: 0.7s;
	animation-delay: 1.5s;
	animation-fill-mode: forwards;
	letter-spacing: 0.1rem;
}

.contactInfo {
	line-height: 2rem;
	font-size: 1.5rem;
}

.contactBanner {
	padding-top: 3rem;
	background-color: black;
	height: 100vh;
	background-position: center top;
	background-size: cover;
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.contactHeader {
	text-align: center;
	margin: 20px auto;
	font-weight: bold;
	font-size: 3.5rem;
}

.contactDiv h3 {
	margin-bottom: 0.5rem;
	text-align: center;
}

.contactDiv {
	margin: 1.5rem auto;
	font-size: 1.5rem;
	text-align: center;
}


@media only screen and (max-width: 600px) {
	.ListProducts {
	display: flex;
	flex-direction: column;
}	
}

@media only screen and (max-width: 588px) {
.mobileProductsCat {
	display: block;
}	

.products-category {
	display: none;
}

}


@media only screen and (max-width: 480px) {
.tabletPic {
	display: none;
}

.mobilePic {
	display: initial;
}

}

@media only screen and (max-width: 1111px) {
	.contactDetailsTemplate {
	transform: translateY(-60%);
	opacity: 0;
	width: 50%;
	margin: 4.5rem 0 0 3rem;
	animation-name: emergeDetails;
	animation-duration: 0.7s;
	animation-delay: 2s;
	animation-fill-mode: forwards;
	letter-spacing: 0.1rem;
}	
}

@media only screen and (max-width: 975px) {
	.contactDetailsTemplate {
	transform: translateY(-60%);
	opacity: 0;
	width: 50%;
	margin: 4.5rem 0 0 3rem;
	animation-name: emergeDetails;
	animation-duration: 0.7s;
	animation-delay: 2s;
	animation-fill-mode: forwards;
	letter-spacing: 0.1rem;
}

.contactTemplate {
	background-color: black;
	color: white;
	filter: brightness(0%);
	font-family: 'Teko', sans-serif;
	animation-name: dimAndZoom;
	animation-delay: 0.5s;
	animation-duration: 2s;
	animation-fill-mode: forwards;	
}

.contactDiv {
	margin: 1.5rem auto;
	font-size: 1.5rem;
}

.contactBanner {
	padding-top: 2rem;
	background-color: black;
	height: 100vh;
	background-position: center;
	background-size: cover;
	color: white;
}

.contactHeader h1 {
	text-align: left;
	font-size: 5rem;
}

}

@media only screen and (max-width: 807px) {
	.contactDetailsTemplate {
	transform: translateY(-60%);
	opacity: 0;
	width: 50%;
	margin: 4.5rem 0 0 3rem;
	animation-name: emergeDetails;
	animation-duration: 0.7s;
	animation-delay: 2s;
	animation-fill-mode: forwards;
	letter-spacing: 0.1rem;
}

.contactTemplate {
	background-color: black;
	color: white;
	filter: brightness(0%);
	font-family: 'Teko', sans-serif;
	animation-name: dimAndZoom;
	animation-delay: 0.5s;
	animation-duration: 2s;
	animation-fill-mode: forwards;	
}

.contactDiv {
	margin: 1.5rem auto;
	font-size: 1rem;
}

.contactDiv h3 {
	font-size: 1.5rem;
}

.contactBanner {
	padding-top: 1.5rem;
	background-color: black;
	height: 100vh;
	background-position: 60% 0%;
	background-size: cover;
	color: white;
}

.contactHeader {
	text-align: left;
	font-size: 2rem;
}

}

@media only screen and (max-width: 549px) {

.contactBanner {
	padding-top: 1.5rem;
	background-color: black;
	height: 100vh;
	background-position: 70% 0%;
	background-size: cover;
	color: white;
}

}


@media only screen and (max-width: 487px) {
.contactHeader {
	text-align: center;
	font-size: 2rem;
	margin-bottom: 0.5rem;
}

.contactBanner {
	background-color: black;
	padding-top: 1.5rem;
	height: 100vh;
	background-position: 70% 0%;
	color: white;
}	

.contactDetailsTemplate {
	transform: translateY(-60%);
	opacity: 0;
	width: 90%;
	text-align: center;
	margin: 2rem auto;
	animation-name: emergeDetails;
	animation-duration: 0.7s;
	animation-fill-mode: forwards;
	letter-spacing: 0.1rem;
	animation-delay: 0.5s;
}

.contactTemplate {
	background-color: black;
	color: white;	
}

.contactInfo {
	line-height: 2rem;
	font-size: 1.2rem;
}

.contactDiv h3 {
	font-size: 1.7rem;
	line-height: 2rem;
}

}