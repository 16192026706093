.paymentTemplate {
	width: 60%;
	margin: auto;
	padding-top: 3rem;
}

.formLabel {
	display: block;
	text-align: center;
	width: 40%;
	text-align: center;
	margin: 1rem auto 0.5rem;
}

.paymentForm img {
	padding: 1rem 0;
	width: 60%;
	margin: auto;
	border-top: 1px solid gray;
	border-bottom: 1px solid gray;
}

.expDate {
	display: flex;
	width: 100%;
	margin: 1rem auto;
	justify-content: center;	
}

.paymentForm {
	border: solid gray 1px;
	background-color: black;
	color: white;
	border: 1px solid white;
	box-shadow: 3px 4px 4px 5px gray;
}

.paymentForm h1 {
	margin: 1rem auto;
	width: 100%;
	display: block;
	text-align: center;
}

.paymentForm input {
	padding: 0.3rem 1.4rem;
	width: 40%;
	display: block;
	margin: auto;
}

.paymentForm input:focus {
	border: solid 2px #B48B4C;
}

.expDate input {
	width: 15%;
	margin: auto 0.4rem;
}

@media only screen and (max-width: 997px) {
.paymentTemplate {
	width: 85%;
	margin: auto;
	padding-top: 3rem;
}

}

@media only screen and (max-width: 503px) {
.paymentForm input {
	padding: 0.3rem 1.4rem;
	width: 70%;
	display: block;
	margin: auto;
}
.expDate input {
	width: 25%;
	margin: auto 0.4rem;
}

.paymentComponentContainer button {
	width: 80%;
}

}