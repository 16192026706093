.InsideTheShopTemplate {
	background-color: black;
	color: white;
	padding-top: 3rem;
	min-height: 100vh;
	padding-bottom: 2rem;
}

.insideShopDiv {
	width: 100%;
	padding: 1rem;
}

.shopImg {
	width: 100%;
}

.shopDetails {
	width: 100%;
	line-height: 20px;
}

img {
	width: 100%;
}

.InsideTheShopTemplate h1, .InsideTheShopTemplate h2 {
	text-align: center;
	margin: 1rem auto;
}

.sideBySideTemplate {
	display: flex;
	width: 80%;
	margin: auto;
}

@media only screen and (max-width: 763px) {
.sideBySideTemplate {
	display: flex;
	flex-direction: column;
}

h2 {
	border-bottom: 1px white solid;
	padding-bottom: 0.5rem;
}

}

@media only screen and (max-width: 457px) {
.sideBySideTemplate {
	display: flex;
	flex-direction: column;
	width: 90%;
}

h2 {
	border-bottom: 1px white solid;
	padding-bottom: 0.5rem;
}

}