.bookerServiceContainer {
	width: 60%;
	margin: 6rem auto;
	border: 1px solid gray;
	color: lightgray;
	box-shadow: 6px 3px 5px gray;
	text-align: center;
	height: 500px;
	background-color: black;
	position: relative;
	overflow: hidden;

}

.basketContainer {
	margin-top: 5rem;
}

.responsiveAppointment {
	width: 80%;
	margin: 5rem auto;
	background-color: black;
	color: white;
	padding: 1rem;
	text-align: center;
	display: none;
}

.showContent {
	display: block;
}

.appHeading {
	display: block;
	width: 100%;
	text-align: center;
	margin: 0.5rem auto;
}

.responsiveBtn {
	width: 80%;
	font-family: 'Teko', sans-serif;	
	padding: 0.2rem 2rem;
	background-color: white;
	color: black;
	font-size: 1.2rem;
	letter-spacing: 0.1rem;
	border: none;
	display: block;
	margin: 1.5rem auto;
	transition: 1s all;	
}

.hideContent {
	display: none;
}

.bookingSlideContent {
	height: 2500px;
	width: 100%;
	position: absolute;
}

.bookingSlideContentBarbers {
	height: 2500px;
	width: 100%;
	top: -500px;
	position: absolute;
}

.bookingSlideContentDate {
	height: 2500px;
	width: 100%;
	top: -1000px;
	position: absolute;
}

.bookingSlideContentDetails {
	height: 2500px;
	width: 100%;
	top: -1500px;
	position: absolute;
}


.goToBarbers {
	animation-name: slideDown;
	animation-delay: 0.8s;
	animation-fill-mode: forwards;
	animation-duration: 1.3s;
}

.goToDateTime {
	animation-name: slideDownTwo;
	animation-delay: 0.8s;
	animation-fill-mode: forwards;
	animation-duration: 1.3s;
}

.goToDetails {
	animation-name: slideDownThree;
	animation-delay: 0.8s;
	animation-fill-mode: forwards;
	animation-duration: 1.3s;
}

.goToConfirmation {
	animation-name: slideDownFour;
	animation-delay: 0.8s;
	animation-fill-mode: forwards;
	animation-duration: 1.3s;
}


.dateTimeSelection {
	display: flex;
	width: 100%;
	margin: 1.5rem auto;
	justify-content: space-around;
}

.daySelection, .timeSelection {
	width: 45%;
	border: 0.5px solid gray;
}

.timeList {
	display: inline-block;
	width: 23%;
	cursor: pointer;
	margin: 0.5rem;
	transition: 1s all;
}

.formNames {
	 margin: auto;
	width: 95%;
}

.formNames div {
	width: 50%;
	margin: 1rem auto;
}

.formNames input {
	margin: 0.2rem auto;
	width: 85%;
	padding: 0.3rem 0.5rem;
}

.dayList {
	cursor: pointer;
	display: block;
	width: 100%;
	border-bottom: 0.5px solid white;
	padding: 0.5rem;
	transition: 0.5s all;
}

.dayList:hover, .timeList:hover, .selectedDateTime {
	background-color: #B48B4C;
	color: black;
}


.daySelection span:last-child {
	border-bottom: none;
}

.daySelection h3, .timeSelection h3 {
	background-color: gray;
	color: white;
	padding: 0.5rem;
}

.sliderDiv {
	padding: 1rem 2rem;
	width: 100%;
	background-color: black;
	height: 500px;
	position: relative;
}

.spanTag {
	display: block;
	margin: 0.5rem auto;
}

.checkBox {
	font-size: 0.7rem;
	width: 100%;
}

.checkBox input {
	display: inline-block;
	width: 3%;
}

.navBack {
	cursor: pointer;
}

.introHeading {
	font-size: 0.9rem;
}

h4 {
	color: white;
	margin: 1rem auto 0.1rem;
}

button {
	cursor: pointer;
	width: 40%;
	font-family: 'Teko', sans-serif;	
	padding: 0.2rem 2rem;
	background-color: white;
	color: black;
	font-size: 1.2rem;
	letter-spacing: 0.1rem;
	border: none;
	display: block;
	margin: 1.5rem auto;
	transition: 1s all;
}

.selectBarberBtn {
	cursor: pointer;
	width: 90%;
	margin: auto;
	background-color: black;
	font-family: 'Teko', sans-serif;	
	color: white;	
	border: 1px solid white;
	position: absolute;
	bottom: 0;
}

.activeBarberBtn {
	cursor: pointer;
	width: 90%;
	margin: auto;
	background-color: #B48B4C;
	color: black;
	font-family: 'Teko', sans-serif;	
	border: 1px solid white;
	position: absolute;
	bottom: 0;	
}

.barberComponent img {
	width: 85%;
}

.summaryBox {
	width: 100%;
	margin: 2rem auto;
}

.summaryHeading {
	background-color: white;
	text-transform: uppercase;
	font-size: 0.9rem;
	letter-spacing: 0.2rem;
	color: black;
	text-align: center;
	display: block;
	width: 100%;
	padding: 0.6rem 1rem;
}

.summaryBreakdown {
	display: flex;
	width: 100%;
	margin: 0.5rem auto;
}

.summaryDetail {
	width: 30%;
	margin: 0 1rem;
	padding-top: 0.5rem;
	text-transform: uppercase;
}

.barberComponent {
	width: 17%;
	display: flex;
	height: 300px;
	position: relative;
}

.barberImg {
	width: 60%;
	overflow: hidden;
}

.barberInfo {
	width: 35%;
	text-align: left;
	position: relative;
}

.barberBtn {
	width: 100%;
	background-color: black;
	color: white;
	border: 1px solid white;
	font-size: 1rem;
}

.selectBarber {
	margin: auto;
	display: flex;
	position: absolute;
	width: 600%;
	height: 450px;
	overflow: hidden;
	transition: 1.5s all;
}

.revealResponsiveBarber {
	animation-name: revealBarber;
	animation-delay: 1s;
	animation-duration: 1s;
	animation-fill-mode: forwards;
}

.returnToBarbers {
	animation-name: returnToBarbers;
	animation-duration: 1s;
	animation-fill-mode: forwards;	
}

.firstSlide {
	left: 0;
}

.secondSlide {
	left: -100%;
}

.thirdSlide {
	left: -200%;
}

.fourthSlide {
	left: -300%;
}

.fifthSlide {
	left: -400%;
}

.sixthSlide {
	left: -500%;
}

.boldTag {
	display: inline-block;
	font-weight: bold;
}

.selectedBarber {
	background-color: white;
	color: black;
}

.toggleBarbers {
	color: white;
	display: flex;
	width: 100%;
	justify-content: center;
	margin: 2rem auto;
	border-top: 1px solid white;
	padding-top: 1rem;
	position: absolute;
	bottom: 15px;
	left: 0;
	transition: 1.5s all;
}

.chooseBarber {
	width: 10%;
	display: inline-block;
	padding: 0;
	color: white;
	margin: auto 0.5rem;
	background-color: black;
	border: none;
	font-size: 1rem;
}

.activeBarber {
	width: 10%;
	display: inline-block;
	padding: 0;
	color: orange;
	margin: auto 0.5rem;
	background-color: black;
	border: none;
	font-size: 1rem;	
}

select {
	text-align: center;
}

.mobileBarberSection {
	width: 100%;
	background-color: black;
	color: white;
	overflow: hidden;
	transition: 1.5s all;
	display: flex;
	text-align: center;
	position: relative;
	height: 900px;
}

.mobileBarbersContainer {
	width: 200%;
	background-color: black;
	position:absolute;
	display: flex;
	left: 0;
	transition: 2s all;
}

.responsiveBarberOption {
	width: 90%;
	margin: 1rem auto;
	font-size: 0.7rem;
}

.responsiveBarberOption button {
	width: 80%;
	font-family: 'Teko', sans-serif;	
	padding: 0.2rem 2rem;
	background-color: white;
	color: black;
	font-size: 1.2rem;
	letter-spacing: 0.1rem;
	border: none;
	display: block;
	margin: 1rem auto;
	transition: 1s all;	
}

.responsiveBarberOption img {
	width: 80%;
	margin: auto;
}

.backToBarbers {
	color: orange;
	font-size: 1.3rem;
}

.responsiveSummaryHeading {
	width: 85%;
	margin: 1rem auto;
	font-size: 0.8rem;
	display: block;
	padding: 0.5rem;
	color: black;
	background-color: lightgray;
}

.responsiveFormLabel {
	display: block;
	text-align: center;
	width: 100%;
	margin: 0.5rem auto;
}

.responsiveFormField {
	width: 80%;
	margin: 0.2rem auto;
	padding: 0.3rem 1rem;
	border-radius: 10px;
}

.mobileBarberPick {
	width: 50%;

}

.mobileBarberProfile {
	width: 50%;
}

.barberFlex {
	width: 90%;
	margin: 0.5rem auto;
	display: flex;
	justify-content: space-around;
}

.barberTemplate {
	width: 50%;
	margin: 0.5rem;
	position: relative;
}

.barberFlex div img {
	width: 100%;
}

.barberProfileNameTag {
	width: 100%;
	display: block;
	text-align: center;
	font-weight: bold;
	font-size: 0.7rem;
	letter-spacing: 0.1em;
}

.barberProfileSkillsTag {
	width: 100%;
	display: block;
	text-align: center;
	font-size: 0.6rem;	
}

.activeBarber {
	width: 10%;
	display: inline-block;
	padding: 0;
	color: orange;
	margin: auto 0.5rem;
	background-color: black;
	border: none;
	font-size: 1rem;	
}

.mobileBarbers {
	display: none;
}

@keyframes slideDown {
    from {top: 0px;}
    to {top: -500px;}
}

@keyframes slideDownTwo {
    from {top: -500px;}
    to {top: -1000px;}
}

@keyframes slideDownThree {
    from {top: -1000px;}
    to {top: -1500px;}
}

@keyframes slideDownFour {
    from {top: -1500px;}
    to {top: -2000px;}
}

@keyframes revealBarber {
	from {left: 0;}
	to {left: -100%;}
}

@keyframes returnToBarbers {
	from {left: -100%;}
	to {left: 0%;}
}

@media only screen and (max-width: 1140px) {
 .bookerServiceContainer {
 	width: 75%;
 }	
}


@media only screen and (max-width: 912px) {
 .bookerServiceContainer {
 	width: 85%;
 }	
}


@media only screen and (max-width: 812px) { M
 .bookerServiceContainer {
 	width: 90%;
 }	

.barberImg {
	width: 50%;
	overflow: hidden;
}

.barberInfo {
	width: 50%;
	text-align: left;
	position: relative;
	padding: 0 1rem 0 0;
}

.selectBarberBtn {
	cursor: pointer;
	width: 90%;
	margin: auto;
	background-color: black;
	font-family: 'Teko', sans-serif;	
	color: white;	
	border: 1px solid white;
	position: absolute;
	bottom: 32px;
}

}

@media only screen and (max-width: 700px) {
.responsiveAppointment {
	width: 80%;
	margin: 5rem auto;
	background-color: black;
	color: white;
	padding: 1rem;
	text-align: center;
	display: block;
}

.bookerServiceContainer {
	display: none;
}

}

@media only screen and (max-width: 615px) {
.mobileBarberSection {
	width: 100%;
	background-color: black;
	color: white;
	overflow: hidden;
	transition: 1.5s all;
	display: flex;
	text-align: center;
	position: relative;
	height: 800px;
}

}

@media only screen and (max-width: 538px) {
.mobileBarberSection {
	width: 100%;
	background-color: black;
	color: white;
	overflow: hidden;
	transition: 1.5s all;
	display: flex;
	text-align: center;
	position: relative;
	height: 700px;
}

}


@media only screen and (max-width: 444px) {
.mobileBarberSection {
	width: 100%;
	background-color: black;
	color: white;
	overflow: hidden;
	transition: 1.5s all;
	display: flex;
	text-align: center;
	position: relative;
	height: 600px;
}

}

@media only screen and (max-width: 358px) {
.mobileBarberSection {
	width: 100%;
	background-color: black;
	color: white;
	overflow: hidden;
	transition: 1.5s all;
	display: flex;
	text-align: center;
	position: relative;
	height: 500px;
}

}