.recContainer {
	background-color: black;
	color: white;
	padding-bottom: 60px;
}

.boldFont {
	font-weight: bold;
}

.recBanner {
	width: 100%;
	height: 500px;
	background-image: url("../assets/recBanner.jpg");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	margin-bottom: 30px;
}

.joinIntro {
	color: white;
	text-align: center;
	width: 75%;
	margin: auto;
	display: flex;
	justify-content: space-between;
	background-color: black
}

.mobileRec {
	display: none;
	color: #3a3a3a;
	width: 75%;
	margin: auto;
	justify-content: space-between;	
}

.mobileJobSpec {
	list-style-type: none;
}

.mobileJobSpec li {
	margin: 2px auto;
}

.join-text ul {
	margin-left: 10px;
}

.join-text {
	width: 75%;
	text-align: left;
}

.jobTitle {
	 margin: 20px auto;
}

.join-positions {
	width: 20%;
	text-align: center;
	border: 0.5px solid white;
}

.join-positions ul {
	list-style-type: none;
}

.join-positions h3 {
	margin: 0 auto 10px;
	letter-spacing: 0.8px;
	background-color: white;
	color: black;
	font-weight: bold;
	padding: 5px;
}

.join-positions ul li {
	margin: 15px auto;
}


.jobTitle {
	font-size: 1.4rem;
	letter-spacing: 1px;
}

.applyLink {
	color: navy;
	border-bottom: dotted 1px black;
}


@media only screen and (max-width: 1134px) {

.joinIntro {
	color: #3a3a3a;
	text-align: center;
	width: 85%;
	margin: auto;
	display: flex;
	justify-content: space-between;
}

.join-positions {
	width: 20%;
	text-align: center;
	border: 0.5px solid lightgray;
	padding: 10px;
}

} 

@media only screen and (max-width: 900px) {
	.joinIntro {
	display: none;
}

	.mobileRec {
	display: block;
	color: white;
	width: 75%;
	margin: 10px auto;	
}

}

@media only screen and (max-width: 768px) {
.recBanner {
	width: 100%;
	height: 400px;
	background-image: url("../assets/recBanner.jpg");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	margin-bottom: 30px;
}	

}

@media only screen and (max-width: 768px) {
.recBanner {
	width: 100%;
	height: 300px;
	background-image: url("../assets/recBanner.jpg");
	background-size: cover;
	background-position: left;
	background-repeat: no-repeat;
	margin-bottom: 30px;
}	

}