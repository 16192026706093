
.basketHeading {
	margin: 1rem auto;
	display: flex;
	width: 85%;
	list-style-type: none;
	justify-content: space-between;
	border: 1px solid lightgray;
	padding: 1rem 6rem;
	font-family: 'Teko', sans-serif;
	font-size: 1.1rem;
	letter-spacing: 0.1rem;
	background-color: #f9f9f9;
	border: none;
}

.checkoutList li {
	width: 25%;
	text-align: center;
}

.checkoutList {
	margin: 1rem auto;
	display: flex;
	align-items: center;
	width: 85%;
	list-style-type: none;
	justify-content: space-between;
	padding: 0.5rem 4rem;
	font-family: 'Teko', sans-serif;
	font-size: 1rem;
	letter-spacing: 0.1rem;	
	border-bottom: 1px lightgray solid;
	box-shadow: 5px 5px 5px 5px gray;
}

input {
	display: block;
	margin: auto;
	width: 25%;
}

.checkoutList img {
	width: 70%;
}

.qty-change {
	margin: 1.5rem 0.5rem 0;
	padding: 0.3rem;
	color: white;
	font-size: 0.4rem;
	background-color: gray;
	text-align: center;
	cursor: pointer;
}

.subTotalCheckout {
	box-shadow: 5px 5px 6px 4px gray;
	width: 85%;
	cursor: pointer;
	background-color: black;
	display: flex;
	justify-content: center;
	color: white;
	padding: 1rem 2rem;
	margin: 1rem auto;
}

.subTotalCheckout span {
	margin: auto 3rem;
	letter-spacing: 0.1rem;
	display: block;
	width: 100%;
	text-align: center;
}

.responsiveLabel {
	display: none;
}

.deleteItem {
	display: block;
	margin: auto;
	color: red;
	width: 80%;
	cursor: pointer;
}

@media only screen and (max-width: 976px) {

.subTotalCheckout {
	width: 85%;
	cursor: pointer;
	background-color: black;
	display: flex;
	justify-content: center;
	color: white;
	padding: 1rem 2rem;
	margin: 1rem auto;
	transition: 1s all;
}	

}

@media only screen and (max-width: 670px) {
	.checkoutList {
		display: flex;
		padding: 1rem;
		flex-direction: column;
		width: 90%;
		font-size: 1.2rem;
	}

.checkoutList li {
	margin: 0.3rem auto;
	width: 100%;
}

.checkoutList li img {
	width: 70%;
	border-bottom: 1px solid lightgray;
	padding-bottom: 1rem;
}

.responsiveLabel {
	display: initial;
	color: black;
}

.basketHeading {
	display: none;
}

.subTotalCheckout {
	width: 90%;
	cursor: pointer;
	background-color: black;
	display: flex;
	justify-content: center;
	color: white;
	padding: 1rem 2rem;
	margin: 1rem auto;
}	

}

@media only screen and (max-width: 430px) {

.subTotalCheckout {
	width: 90%;
	cursor: pointer;
	font-size: 0.6rem;
	background-color: black;
	display: flex;
	justify-content: center;
	color: white;
	padding: 0.5rem;
	margin: 1rem auto;
}	

}