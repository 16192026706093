.walkInServicesTemplate {
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-image: url("../assets/childrenservices.jpg");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.walkInServicesTemplateKids {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100vh;
	width: 100%;
	background-image: url("../assets/lookBook/lbBanner.jpg");
	background-size: cover;
	background-position: center top;
	background-repeat: no-repeat;
}

.walkInservicesDiv {
	line-height: 1.8rem;
	text-align: center;
	background-color: black;
	color: white;
	width: 100%;
	animation-name: slideUp;
	animation-duration: 1.5s;
	animation-delay: 1s;
	animation-fill-mode: forwards;
	opacity: 0;
	height: 100vh;
	font-size: 1.8rem;
	padding-top: 3rem;
	font-family: 'Teko', sans-serif;
}

.walkInservicesDiv h2 {
	border-bottom: 1px solid white;
	padding-bottom: 1rem;
	width: 45%;
	margin: 3rem auto 2rem;
}

.walkInservicesDiv p {
	font-size: 1.4rem;
	line-height: 25px;
}

@keyframes slideUp {
	from {opacity: 0; transform: translateY(500px);}
	to {opacity: 0.9; transform: translateY(0);}
}

@media only screen and (max-width: 1090px) {
.walkInservicesDiv h2 {
	border-bottom: 1px solid white;
	padding-bottom: 1rem;
	width: 60%;
	margin: 2.5rem auto 0;
}	
}

@media only screen and (max-width: 861px) {
.walkInservicesDiv h2 {
	border-bottom: 1px solid white;
	padding-bottom: 1rem;
	width: 80%;
	margin: 2.5rem auto 0;
}	
}

@media only screen and (max-width: 667px) {
.walkInservicesDiv {
	line-height: 1.8rem;
	text-align: center;
	background-color: black;
	padding: 3rem;
	color: white;
	width: 100%;
	position: absolute;
	animation-name: slideUp;
	animation-duration: 1.5s;
	animation-delay: 1s;
	animation-fill-mode: forwards;
	opacity: 0;
	height: 99vh;
	font-size: 1.3rem;
	font-family: 'Teko', sans-serif;
}	
}

@media only screen and (max-width: 509px) {
.walkInservicesDiv h2 {
	border-bottom: 1px solid white;
	padding-bottom: 1rem;
	width: 95%;
	margin: 2.5rem auto 0;
}	

.walkInServicesTemplateKids {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100vh;
	width: 100%;
	background-image: url("../assets/kids-services-mobile.jpg");
	background-size: cover;
	background-repeat: no-repeat;
}

.walkInServicesTemplate {
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-image: url("../assets/kidsServicesMobile.jpg");
	background-size: cover;
	background-repeat: no-repeat;
}

}

@media only screen and (max-width: 437px) {
.walkInservicesDiv {
	line-height: 1.5rem;
	text-align: center;
	background-color: black;
	padding: 2rem;
	color: white;
	width: 100%;
	position: absolute;
	animation-name: slideUp;
	animation-duration: 1.5s;
	animation-delay: 1s;
	animation-fill-mode: forwards;
	opacity: 0;
	height: 99vh;
	font-size: 1.3rem;
	font-family: 'Teko', sans-serif;
}	
}