.DesktopBanner {
	width: 100%;
	height: 95vh;
	background-image: url('../assets/newImages/wetransfer-552d7c/homeBanner.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	animation-name: lowerBrightness;
	animation-duration: 3s;
	animation-fill-mode: forwards;
}

.bannerText {
	position: absolute;
	width: 100%;
	margin: auto;
	height: 400px;
	top: 45%;
}

.sigText {
	width: 100%;
	display: block;
	text-align: center;
	font-size: 0.7rem;
	margin-top: 4px;
	cursor: pointer;
}

.strong {
	letter-spacing: 0.1px;
}

.introText p {
	font-size: 0.9rem;
	line-height: 20px;
}


.bannerHeading {
	width: 100%;
	text-align: center;
	margin: 1rem auto 3rem;
	letter-spacing: 0.1rem;
	display: block;
	font-family: 'Teko', sans-serif;
	font-size: 2.2rem;
	color: white;
	filter: brightness(100%);
	opacity: 0;
	animation-name: appearFromTop;
	animation-delay: 0.5s;
	animation-duration: 1s;
	animation-fill-mode: forwards;
}

 h1 {
 	padding-bottom: 0.5rem;
	margin-bottom: 1rem;
	font-size: 1rem;
	letter-spacing: 0.1rem;
}

.bannerBtn {
	width: 80%;
	margin: auto;
	color: white;
	text-shadow: 6px 6px 6px black;
	display: block;
	font-family: 'Teko', sans-serif;
	font-weight: 600;
	font-size: 6.2rem;
	text-align: center;
	transition: 1s all;
	opacity: 0;
	animation-name: appearFromBottom;
	animation-delay: 1s;
	animation-duration: 1s;
	animation-fill-mode: forwards;	
}

section {
	text-align: center;
	color: black;
	width: 65%;
	margin: 1.5rem auto;
}

section h1 {
	display: inline-block;
	width: 100%;
	text-align: center;
	font-size: 1.1rem;
}

.essentials {
	margin: 1rem auto;
	display: flex;
	justify-content: space-between;
	text-align: center;
	width: 100%;
}

.product-image-details {
	width: 25%;
	margin: 0 0.5rem;
}

.product-image-details img {
	width: 100%;
}

.mainServices {
	margin: 0;
	display: flex;
	align-items: stretch;
}

.introText {
	text-align: center;
	color: white;
	width: 50%;
	margin: auto;
}

.mainIntro {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 650px;
	width: 100%;
	margin: 0;
	background-image: url("../assets/introWallpaper2.JPG");
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	background-repeat: no-repeat;
	position: relative;
}

.services-img {
	width: 50%;
	background-color: black;
}

.services-img img {
	object-fit: contain;
	width: 100%;
	height: 100%;
}

.servicesInfo {
	background-color: black;
	color: #dbdbdb;	
	width: 50%;
	padding: 0 5rem;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

li {
	text-transform: capitalize;
}

.introDiv {
	background-color: black;
	color: white;
}

.servicesInfo ul {
	list-style-type: none;
}

.contentBtn {
    font-family: 'Noto Sans KR', sans-serif;	
	margin: 1.2rem auto;
	border: 1px solid white;
	width: 75%;
	font-size: 0.9rem;
	padding: 0.2rem 1rem;
	background-color: black;
	color: white;
	transition: 1s all;
}

.contentBtn:hover {
	background-color: #009A56;
	color: black;
}

@keyframes lowerBrightness {
	from {filter: brightness(100%);}
	to {filter: brightness(40%);}
}

@keyframes appearFromTop {
	from {opacity: 0; transform: translateY(-2000px);}
	to {opacity: 0.9; transform: translateY(0px);}
}

@keyframes appearFromBottom {
	from {opacity: 0; transform: translateY(300px);}
	to {opacity: 0.9; transform: translateY(0px);}	
}


@media only screen and (max-width: 1104px) {
.servicesInfo {
	background-color: black;
	color: #dbdbdb;	
	width: 50%;
	padding: 0 1.5rem;
	font-size: 0.7rem;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
}	
}

@media only screen and (max-width: 998px) {
.bannerBtn {
	cursor: pointer;
	width: 50%;
	margin: 1.5rem auto;
	color: white;
	display: block;
	font-family: 'Teko', sans-serif;	
	font-weight: 600;
	font-size: 2.8rem;
	padding: 1rem;	
	text-align: center;
	transition: 1s all;
	opacity: 0;
	animation-name: appearFromBottom;
	animation-delay: 1.5s;
	animation-duration: 1.2s;
	animation-fill-mode: forwards;	
}

}

@media only screen and (max-width: 950px) {

.essentials {
	margin: 1rem auto;
	display: flex;
	justify-content: space-between;
	text-align: center;
	width: 100%;
}

.mainServices {
	margin: 0;
	display: flex;
	flex-direction: column;

}	

.services-img {
	width: 100%;
	background-color: black;
}

.services-img img {
	overflow: hidden;
}


.servicesInfo {
	background-color: black;
	color: #dbdbdb;	
	width: 100%;
	padding: 1rem 8rem;
	font-size: 0.8rem;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.order1 {
	order: 1;
}

.order2 {
	order: 2;
	margin: 0;
}

}


@media only screen and (max-width: 712px) {


.bannerBtn {
	cursor: pointer;
	width: 65%;
	margin: 1.5rem auto;
	color: white;
	display: block;
	font-family: 'Teko', sans-serif;	
	font-weight: 600;
	font-size: 2.2rem;
	padding: 2rem;	
	text-align: center;
	transition: 1s all;
	opacity: 0;
	animation-name: appearFromBottom;
	animation-delay: 1.5s;
	animation-duration: 1.2s;
	animation-fill-mode: forwards;	
}
	
}

@media only screen and (max-width: 764px) {

section {
	background-color: #FFFFFF;
	color: #848b8e;
	text-align: justify;
}


.servicesInfo {
	background-color: black;
	color: #dbdbdb;	
	width: 100%;
	line-height: 1.4rem;
	padding: 0.2rem 2rem;
	font-size: 0.8rem;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
}	

.services-img {
	width: 100%;
	background-color: black;
}

}

@media only screen and (max-width: 640px) {
.servicesInfo {
	background-color: black;
	color: #dbdbdb;	
	width: 100%;
	min-height: 350px;
	line-height: 1.4rem;
	padding: 0.5rem 2rem;
	font-size: 0.8rem;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.mainIntro {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 550px;
	width: 100%;
	margin: 0;
	background-image: url("../assets/introWallpaper2Mobile.JPG");
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	background-repeat: no-repeat;
	position: relative;
}

.introText {
	text-align: center;
	color: white;
	width: 90%;
	margin: auto;
}

.hideSentence {
	display: none;
}

}

@media only screen and (max-width: 592px) {

.mainIntro {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 500px;
	width: 100%;
	padding: 25px auto;
	background-image: url("../assets/missionstatementmobile.jpg");
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	background-repeat: no-repeat;
	position: relative;
}

.DesktopBanner {
	width: 100%;
	height: 95vh;
	background-image: url('../assets/homebannermobile.jpg');
	background-size: cover;
	background-position: center;
	animation-name: lowerBrightness;
	animation-duration: 3s;
	animation-fill-mode: forwards;
}


.bannerBtn {
	cursor: pointer;
	width: 85%;
	margin: 1.5rem auto;
	color: white;
	display: block;
	font-family: 'Teko', sans-serif;	
	font-weight: 600;
	font-size: 2.2rem;
	padding: 1rem;	
	text-align: center;
	transition: 1s all;
	opacity: 0;
	animation-name: appearFromBottom;
	animation-delay: 1.5s;
	animation-duration: 1.2s;
	animation-fill-mode: forwards;	
}

}

@media only screen and (max-width: 416px) {

.bannerText {
	position: absolute;
	width: 100%;
	margin: auto;
	height: 400px;
	top: 50%;
}


.bannerHeading {
	width: 100%;
	text-align: center;
	margin: 1rem auto;
	letter-spacing: 0.1rem;
	display: block;
	font-family: 'Teko', sans-serif;
	font-size: 1.7rem;
	color: white;
	filter: brightness(100%);
	opacity: 0;
	animation-name: appearFromTop;
	animation-delay: 0.3s;
	animation-duration: 1.2s;
	animation-fill-mode: forwards;
}

.bannerBtn {
	cursor: pointer;
	width: 90%;
	margin: 1rem auto;
	color: white;
	font-size: 2rem;
	display: block;
	font-family: 'Teko', sans-serif;	
	font-weight: 600;	
	text-align: center;
	transition: 1s all;
	opacity: 0;
	animation-name: appearFromBottom;
	animation-delay: 0.7s;
	animation-duration: 1.2s;
	animation-fill-mode: forwards;	
}

}

@media only screen and (max-width: 390px) {
.contentBtn {
    font-family: 'Noto Sans KR', sans-serif;	
	margin: 1.2rem auto;
	border: 1px solid white;
	width: 90%;
	font-size: 0.8rem;
	padding: 0.5rem 1rem;
	background-color: black;
	color: white;
	transition: 1s all;
}
}
