.desktopNavDiv {
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0.5rem 2rem;
	background-color: black;
	color: white;
	letter-spacing: 0.1rem;
	font-family: 'Teko', sans-serif;			
	justify-content: space-between;
	font-size: 1.2rem;
	position: fixed;
	z-index: 500000;
	top: 0;
}

.desktopNavContainer {
	position: relative;
}

.extendMobileNav {
	padding-top: 3rem;
	position: absolute;
	z-index: 5000000;
	height: 100vh;
	width: 100%;
	letter-spacing: 0.2rem;
	background-color: black;
	color: white;
	font-family: 'Teko', sans-serif;	
	font-size: 1.5rem;
}

.defaultHide {
	left: -100%;
}

.defaultShow {
	left: 0;
}

.showMobileNav {
	animation-name: slideMobileNavRight;
	animation-duration: 1s;
	animation-fill-mode: forwards;	
}

.hideMobileNav {
	animation-name: slideMobileNavLeft;
	animation-duration: 1s;
	animation-fill-mode: forwards;
}

.mobileNavLinks {
	padding: 0;
	width: 80%;
	margin: 6rem auto 0;
	list-style-type: none;
}

.mobileNavLinks li {
	cursor: pointer;
	width: 100%;
	text-align: center;
	margin: 2.5rem auto;
	transition: 1s all;
}


.mobileNavLinks li:hover {
	color: #009A56;	
}


.mm-tab {
	display: inline-block;
	margin-right: 1rem;
	left: 0;
}

.mobileNav {
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0.5rem 1rem;
	background-color: black;
	color: white;
	font-family: 'Teko', sans-serif;			
	justify-content: space-between;
	font-size: 1.2rem;
	height: 10vh;
	position: fixed;
	z-index: 700000000;
	top: 0;
	display: none;	
}

.mobileMenu {
	width: 85%;
	display: flex;
	align-items: center;
	text-align: left;
	position: relative;
	font-size: 1.6rem;
}

.mobileLogo {
	width: 15%;
	margin-right: 10px;
}

.mobileLogo img {
	height: 40px;
	width: 40px;
	margin: auto;
}

.mobileCart {
	margin-left: 3rem;
	cursor: pointer;
}

.navLinks {
	width: 95%;
}

.navLinks ul {
	letter-spacing: 0.2rem;
	width: 100%;
	list-style-type: none;
	display: flex;
	justify-content: space-around;
}

.navLinks ul li {
	margin: 0 0.5rem;
	cursor: pointer;
	transition: 1s all;
}

.navLinks ul li:hover, .activeLink {
	color: #01CC01;
}

.menuTab {
	cursor: pointer;
	transition: 1s all;
}

.menuTab:hover {
	color: gold;
}

.navLogo {
	width: 5%;
	margin: 0 1rem;
}

.navLogo img {
	cursor: pointer;
	height: 50px;
	width: 70px;
	margin: auto;
}

.desktopLinks {
	list-style-type: none;
	margin: 0 auto;
	display: flex;
	width: 70%;
	justify-content: center;
	font-weight: lighter;
}

.desktopLinks li {
	margin: 0 0.5rem;
}


img {
	width: 100%;
}

@keyframes slideMobileNavRight {
	from {left: -100%;}
	to {left: 0;}
}

@keyframes slideMobileNavLeft {
	from {left: 0;};
	to {left: -100%;}
}	


@media only screen and (max-width: 900px){
.navLinks ul {
	letter-spacing: 0.1rem;
	width: 100%;
	list-style-type: none;
	display: flex;
	justify-content: space-between;
}
}

@media only screen and (max-width: 790px) {
.desktopNavDiv {
	display: none;
}

.navLogo {
	display: none;
}

.mobileNav {
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0.5rem 1rem;
	background-color: black;
	color: white;
	font-family: 'Teko', sans-serif;			
	justify-content: space-between;
	font-size: 1.2rem;
	height: 8vh;
	position: fixed;
	z-index: 700000000;
	top: 0;
}


}

@media only screen and (max-width: 373px) {
	.mobileCart {
		margin-left: 1.2rem
	}

.extendMobileNav {
	padding-top: 3rem;
	position: absolute;
	z-index: 5000000;
	height: 100vh;
	width: 100%;
	letter-spacing: 0.2rem;
	background-color: black;
	color: white;
	font-family: 'Teko', sans-serif;	
	font-size: 1.5rem;
}

.mobileNavLinks li {
	cursor: pointer;
	width: 100%;
	text-align: center;
	margin-top: 2.5rem;
	transition: 1s all;
}

}