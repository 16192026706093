body {
  margin: 0;
  padding: 0;
font-family: 'Noto Sans KR', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12.5px;
  line-height: 1.2rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  padding: 0;
  box-sizing: border-box;
}

.sectionHeading {
	margin: 0.5rem auto;
	letter-spacing: 0.1rem;
}

a {
	color: white;
	font-size: 0.9rem;
}

p {
	margin: 0.5rem auto;
}

.servicesInfo {
	font-size: 0.9rem;
	line-height: 25px;
}

.mobileProductsCat img {
	width: 100%;
	height: 50vh;
}

.products-category {
	width: 100%;
	display: flex;
	height: 100vh;
}

.covid-notice {
	padding: 50px;
	width: 100%;
	height: 600px;
	height: 100vh;
	overflow: scroll;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10000000;
	background-color: white;
	color: black;
	text-align: center;
	line-height: 25px;
}

.aknoledgeNotice {
	width: auto;
	display: inline-block;
	padding: 10px 20px;
	background-color: black;
	color: white;
	margin: 15px auto;
	cursor: pointer;
}

.footer {
	width: 100%;
	color: white;
	padding: 1rem 0;
	text-align: center;
	height: auto;
	background-color: black;
	border-top: gray 0.5px solid;
}

.mobilePic {
	display: none;
}

.mobileProductsCat {
	display: none;
	background-color: black;
}

.mobile-hair-products {
	width: 100%;
	position: relative;
}

.mobile-beard-products {
	width: 100%;	
	position: relative;
}

.ListProducts {
	width: 90%;
	margin: 1rem auto;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}

.productLink {
	position: absolute;
	bottom: 0;
	width: 80%;
	left: 10%;
	border: white 1px solid;
	font-family: 'Teko', sans-serif;
	color: white;
	background-color: black;
	-webkit-transition: 1s all;
	transition: 1s all;
}

.beard-products-selected {
	background-image: url(/static/media/beard-products.fc339013.jpg);
	background-size: cover;
	background-position: 100% 80%;
	height: 100vh;
	-webkit-filter: brightness(0%);
	        filter: brightness(0%);
	-webkit-animation-name: dimToLightHair;
	        animation-name: dimToLightHair;
	-webkit-animation-delay: 1s;
	        animation-delay: 1s;
	-webkit-animation-duration: 2s;
	        animation-duration: 2s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;				
	width: 50%;
}

.hair-products-selected {
	background-image: url(/static/media/hair-products.cf08f28f.jpg);
	background-size: cover;
	background-position: 1% 80%;
	height: 100vh;	
	-webkit-animation-name: dimToLightHair;	
	        animation-name: dimToLightHair;
	-webkit-filter: brightness(0%);
	        filter: brightness(0%);	
	-webkit-animation-delay: 2s;	
	        animation-delay: 2s;
	-webkit-animation-duration: 2s;
	        animation-duration: 2s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;			
	width: 50%;
	border-right: 0.5px #212121 solid;
}

.productBannerHeading {
	font-family: 'Teko', sans-serif;
	color: white;
	text-align: center;
	font-size: 3rem;
	letter-spacing: 0.2rem;
	width: 100%;
	display: block;
	text-align: center;
	margin: 30% auto 0;
	bottom: 60%;
	opacity: 0;
	-webkit-animation-name: AppearFromTop;
	        animation-name: AppearFromTop;
	-webkit-animation-duration: 2.5s;
	        animation-duration: 2.5s;
	-webkit-animation-delay: 1s;
	        animation-delay: 1s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;	
}


.productBannerBtn {
	width: 50%;
	display: block;
	font-family: 'Teko', sans-serif;
	font-size: 1.5rem;
	letter-spacing: 0.1rem;
	color: white;
	text-align: center;
	background-color: black;
	border: 2px white solid;
	margin: 1% auto 0;
	padding: 0.4rem 3rem;
	opacity: 0;
	-webkit-animation-name: AppearFromBottom;
	        animation-name: AppearFromBottom;
	-webkit-animation-duration: 3s;
	        animation-duration: 3s;
	-webkit-animation-delay: 1.5s;
	        animation-delay: 1.5s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;	
	-webkit-animation-timing-function: linear;	
	        animation-timing-function: linear;
}

.sectionHeading {
	border-bottom: 1px solid white;
}

.hairRange {
	cursor: pointer;	
	position: absolute;
	width: 50%;
	left: 25%;
	opacity: 0;
	display: block;
	font-family: 'Teko', sans-serif;
	font-size: 1.1rem;
	font-weight: lighter;
	letter-spacing: 0.1rem;
	color: white;
	text-align: center;
	background-color: black;
	border: 2px white solid;
	padding: 0.4rem 3rem;
	-webkit-animation-name: AppearFromBottom;
	        animation-name: AppearFromBottom;
	-webkit-animation-delay: 1.5s;
	        animation-delay: 1.5s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	-webkit-animation-duration: 1s;
	        animation-duration: 1s;
	-webkit-transition: 1s all;
	transition: 1s all;			
}

.beardRange {
	cursor: pointer;
	position: absolute;
	width: 50%;
	font-weight: lighter;	
	left: 25%;
	opacity: 0;
	display: block;
	font-family: 'Teko', sans-serif;
	font-size: 1.1rem;
	letter-spacing: 0.1rem;
	color: white;
	text-align: center;
	background-color: black;
	border: 2px white solid;
	padding: 0.4rem 3rem;
	-webkit-animation-name: AppearFromBottom;
	        animation-name: AppearFromBottom;
	-webkit-animation-delay: 2.5s;
	        animation-delay: 2.5s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	-webkit-animation-duration: 1s;
	        animation-duration: 1s;
	-webkit-transition: 1s all;
	transition: 1s all;						
}


.mainHeading {
	text-align: center;
	display: block;
	width: 100%;
}

.beardRange:hover, .hairRange:hover, .productLink:hover, .activeNavLink {
	color: #B48B4C;
}

.hideContent {
	display: none;
}

.mainContent {
	margin-top: 3rem;
}

.btcContent {
	padding: 2rem 0;
	background-color: black;
	color: white;
	margin: auto;
}

@-webkit-keyframes AppearFromBottom {
	from {opacity: 0; -webkit-transform: translateY(380px); transform: translateY(380px);}
	to {opacity: 9; -webkit-transform: translateY(520px); transform: translateY(520px);}	
}

@keyframes AppearFromBottom {
	from {opacity: 0; -webkit-transform: translateY(380px); transform: translateY(380px);}
	to {opacity: 9; -webkit-transform: translateY(520px); transform: translateY(520px);}	
}

@-webkit-keyframes dimToLightHair {
	from {-webkit-filter: brightness(0%);filter: brightness(0%);}
	to {-webkit-filter: brightness(80%);filter: brightness(80%);}
}

@keyframes dimToLightHair {
	from {-webkit-filter: brightness(0%);filter: brightness(0%);}
	to {-webkit-filter: brightness(80%);filter: brightness(80%);}
}


@-webkit-keyframes emergeDetails {
	from {opacity: 0; -webkit-transform: translateY(-60%); transform: translateY(-60%)}
	to {opacity: 1; -webkit-transform: translateY(0%); transform: translateY(0%);}
}


@keyframes emergeDetails {
	from {opacity: 0; -webkit-transform: translateY(-60%); transform: translateY(-60%)}
	to {opacity: 1; -webkit-transform: translateY(0%); transform: translateY(0%);}
}

@-webkit-keyframes dimAndZoom {
	from {-webkit-filter: brightness(0%);filter: brightness(0%);}
	to {-webkit-filter: brightness(100%);filter: brightness(100%);}
}

@keyframes dimAndZoom {
	from {-webkit-filter: brightness(0%);filter: brightness(0%);}
	to {-webkit-filter: brightness(100%);filter: brightness(100%);}
}

@media only screen and (max-width: 1193px) {
.beard-products-selected {
	background-image: url(/static/media/beard-products.fc339013.jpg);
	background-size: cover;
	background-position: contain;
	height: 100vh;
	-webkit-filter: brightness(0%);
	        filter: brightness(0%);
	-webkit-animation-name: dimToLightHair;
	        animation-name: dimToLightHair;
	-webkit-animation-delay: 1s;
	        animation-delay: 1s;
	-webkit-animation-duration: 2s;
	        animation-duration: 2s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;				
	width: 50%;
}

.hair-products-selected {
	background-image: url(/static/media/hair-products.cf08f28f.jpg);
	background-size: cover;
	background-position: contain;
	height: 100vh;	
	-webkit-animation-name: dimToLightHair;	
	        animation-name: dimToLightHair;
	-webkit-filter: brightness(0%);
	        filter: brightness(0%);	
	-webkit-animation-delay: 2s;	
	        animation-delay: 2s;
	-webkit-animation-duration: 2s;
	        animation-duration: 2s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;			
	width: 50%;
	border-right: 0.1px #ededed solid;
}

.hairRange {
	cursor: pointer;	
	position: absolute;
	width: 65%;
	left: 17.5%;
	opacity: 0;
	display: block;
	font-family: 'Teko', sans-serif;
	font-size: 1.1rem;
	font-weight: lighter;
	letter-spacing: 0.1rem;
	color: white;
	text-align: center;
	background-color: black;
	border: 2px white solid;
	padding: 0.4rem 3rem;
	-webkit-animation-name: AppearFromBottom;
	        animation-name: AppearFromBottom;
	-webkit-animation-delay: 1.5s;
	        animation-delay: 1.5s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	-webkit-animation-duration: 1s;
	        animation-duration: 1s;
	-webkit-transition: 1s all;
	transition: 1s all;			
}

.beardRange {
	cursor: pointer;
	position: absolute;
	width: 65%;
	font-weight: lighter;	
	left: 17.5%;
	opacity: 0;
	display: block;
	font-family: 'Teko', sans-serif;
	font-size: 1.1rem;
	letter-spacing: 0.1rem;
	color: white;
	text-align: center;
	background-color: black;
	border: 2px white solid;
	padding: 0.4rem 3rem;
	-webkit-animation-name: AppearFromBottom;
	        animation-name: AppearFromBottom;
	-webkit-animation-delay: 2.5s;
	        animation-delay: 2.5s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	-webkit-animation-duration: 1s;
	        animation-duration: 1s;
	-webkit-transition: 1s all;
	transition: 1s all;						
}

}

@media only screen and (max-width: 1079px) {
.products-category {
	width: 100%;
	display: flex;
	height: 85vh;
}	
}

@media only screen and (max-width: 924px) {
.hairRange {
	cursor: pointer;	
	position: absolute;
	width: 80%;
	left: 10%;
	opacity: 0;
	display: block;
	font-family: 'Teko', sans-serif;
	font-size: 1.1rem;
	font-weight: lighter;
	letter-spacing: 0.1rem;
	color: white;
	text-align: center;
	background-color: black;
	border: 2px white solid;
	padding: 0.4rem 3rem;
	-webkit-animation-name: AppearFromBottom;
	        animation-name: AppearFromBottom;
	-webkit-animation-delay: 1.5s;
	        animation-delay: 1.5s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	-webkit-animation-duration: 1s;
	        animation-duration: 1s;
	-webkit-transition: 1s all;
	transition: 1s all;			
}

.beardRange {
	cursor: pointer;
	position: absolute;
	width: 80%;
	font-weight: lighter;	
	left: 10%;
	opacity: 0;
	display: block;
	font-family: 'Teko', sans-serif;
	font-size: 1.1rem;
	letter-spacing: 0.1rem;
	color: white;
	text-align: center;
	background-color: black;
	border: 2px white solid;
	padding: 0.4rem 3rem;
	-webkit-animation-name: AppearFromBottom;
	        animation-name: AppearFromBottom;
	-webkit-animation-delay: 2.5s;
	        animation-delay: 2.5s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	-webkit-animation-duration: 1s;
	        animation-duration: 1s;
	-webkit-transition: 1s all;
	transition: 1s all;						
}

.hair-products-selected {
	background-image: url(/static/media/hair-products.cf08f28f.jpg);
	background-size: cover;
	background-position: 10% 80%;
	height: 100vh;	
	-webkit-animation-name: dimToLightHair;	
	        animation-name: dimToLightHair;
	-webkit-filter: brightness(0%);
	        filter: brightness(0%);	
	-webkit-animation-delay: 2s;	
	        animation-delay: 2s;
	-webkit-animation-duration: 2s;
	        animation-duration: 2s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;			
	width: 50%;
	border-right: 0.1px #ededed solid;
}

.beard-products-selected {
	background-image: url(/static/media/beard-products.fc339013.jpg);
	background-size: cover;
	background-position: 70% 80%;
	height: 100vh;
	-webkit-filter: brightness(0%);
	        filter: brightness(0%);
	-webkit-animation-name: dimToLightHair;
	        animation-name: dimToLightHair;
	-webkit-animation-delay: 1s;
	        animation-delay: 1s;
	-webkit-animation-duration: 2s;
	        animation-duration: 2s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;				
	width: 50%;
}


}

@media only screen and (max-width: 868px) {
	.ListProducts {
	width: 90%;
	margin: 1rem auto;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}	
}

@media only screen and (max-width: 800px) {
.beard-products-selected {
	background-image: url(/static/media/beard-products.fc339013.jpg);
	background-size: cover;
	background-position: 80% 10%;
	height: 100vh;
	-webkit-filter: brightness(0%);
	        filter: brightness(0%);
	-webkit-animation-name: dimToLightHair;
	        animation-name: dimToLightHair;
	-webkit-animation-delay: 1s;
	        animation-delay: 1s;
	-webkit-animation-duration: 2s;
	        animation-duration: 2s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;				
	width: 50%;
}

.hair-products-selected {
	background-image: url(/static/media/hair-products.cf08f28f.jpg);
	background-size: cover;
	background-position: 20% 10%;
	height: 100vh;	
	-webkit-animation-name: dimToLightHair;	
	        animation-name: dimToLightHair;
	-webkit-filter: brightness(0%);
	        filter: brightness(0%);	
	-webkit-animation-delay: 2s;	
	        animation-delay: 2s;
	-webkit-animation-duration: 2s;
	        animation-duration: 2s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;			
	width: 50%;
	border-right: 0.1px #ededed solid;
}

.hairRange {
	cursor: pointer;	
	position: absolute;
	width: 80%;
	left: 10%;
	opacity: 0;
	display: block;
	font-family: 'Teko', sans-serif;
	font-size: 0.9rem;
	font-weight: lighter;
	letter-spacing: 0.1rem;
	color: white;
	text-align: center;
	background-color: black;
	border: 2px white solid;
	padding: 0.4rem 1rem;
	-webkit-animation-name: AppearFromBottom;
	        animation-name: AppearFromBottom;
	-webkit-animation-delay: 1.5s;
	        animation-delay: 1.5s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	-webkit-animation-duration: 1s;
	        animation-duration: 1s;
	-webkit-transition: 1s all;
	transition: 1s all;			
}

.beardRange {
	cursor: pointer;
	position: absolute;
	width: 80%;
	font-weight: lighter;	
	left: 10%;
	opacity: 0;
	display: block;
	font-family: 'Teko', sans-serif;
	font-size: 0.9rem;
	letter-spacing: 0.1rem;
	color: white;
	text-align: center;
	background-color: black;
	border: 2px white solid;
	padding: 0.4rem 1rem;
	-webkit-animation-name: AppearFromBottom;
	        animation-name: AppearFromBottom;
	-webkit-animation-delay: 2.5s;
	        animation-delay: 2.5s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	-webkit-animation-duration: 1s;
	        animation-duration: 1s;
	-webkit-transition: 1s all;
	transition: 1s all;						
}	
}

.contactTemplate {
	background-color: black;
	color: white;
	-webkit-filter: brightness(0%);
	        filter: brightness(0%);
	font-family: 'Teko', sans-serif;
	-webkit-animation-name: dimAndZoom;
	        animation-name: dimAndZoom;
	-webkit-animation-delay: 0.5s;
	        animation-delay: 0.5s;
	-webkit-animation-duration: 2s;
	        animation-duration: 2s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;	
}

.contactDetailsTemplate {
	-webkit-transform: translateY(-60%);
	        transform: translateY(-60%);
	opacity: 0;
	width: 100%;
	-webkit-animation-name: emergeDetails;
	        animation-name: emergeDetails;
	-webkit-animation-duration: 0.7s;
	        animation-duration: 0.7s;
	-webkit-animation-delay: 1.5s;
	        animation-delay: 1.5s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	letter-spacing: 0.1rem;
}

.contactInfo {
	line-height: 2rem;
	font-size: 1.5rem;
}

.contactBanner {
	padding-top: 3rem;
	background-color: black;
	height: 100vh;
	background-position: center top;
	background-size: cover;
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.contactHeader {
	text-align: center;
	margin: 20px auto;
	font-weight: bold;
	font-size: 3.5rem;
}

.contactDiv h3 {
	margin-bottom: 0.5rem;
	text-align: center;
}

.contactDiv {
	margin: 1.5rem auto;
	font-size: 1.5rem;
	text-align: center;
}


@media only screen and (max-width: 600px) {
	.ListProducts {
	display: flex;
	flex-direction: column;
}	
}

@media only screen and (max-width: 588px) {
.mobileProductsCat {
	display: block;
}	

.products-category {
	display: none;
}

}


@media only screen and (max-width: 480px) {
.tabletPic {
	display: none;
}

.mobilePic {
	display: inline;
	display: initial;
}

}

@media only screen and (max-width: 1111px) {
	.contactDetailsTemplate {
	-webkit-transform: translateY(-60%);
	        transform: translateY(-60%);
	opacity: 0;
	width: 50%;
	margin: 4.5rem 0 0 3rem;
	-webkit-animation-name: emergeDetails;
	        animation-name: emergeDetails;
	-webkit-animation-duration: 0.7s;
	        animation-duration: 0.7s;
	-webkit-animation-delay: 2s;
	        animation-delay: 2s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	letter-spacing: 0.1rem;
}	
}

@media only screen and (max-width: 975px) {
	.contactDetailsTemplate {
	-webkit-transform: translateY(-60%);
	        transform: translateY(-60%);
	opacity: 0;
	width: 50%;
	margin: 4.5rem 0 0 3rem;
	-webkit-animation-name: emergeDetails;
	        animation-name: emergeDetails;
	-webkit-animation-duration: 0.7s;
	        animation-duration: 0.7s;
	-webkit-animation-delay: 2s;
	        animation-delay: 2s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	letter-spacing: 0.1rem;
}

.contactTemplate {
	background-color: black;
	color: white;
	-webkit-filter: brightness(0%);
	        filter: brightness(0%);
	font-family: 'Teko', sans-serif;
	-webkit-animation-name: dimAndZoom;
	        animation-name: dimAndZoom;
	-webkit-animation-delay: 0.5s;
	        animation-delay: 0.5s;
	-webkit-animation-duration: 2s;
	        animation-duration: 2s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;	
}

.contactDiv {
	margin: 1.5rem auto;
	font-size: 1.5rem;
}

.contactBanner {
	padding-top: 2rem;
	background-color: black;
	height: 100vh;
	background-position: center;
	background-size: cover;
	color: white;
}

.contactHeader h1 {
	text-align: left;
	font-size: 5rem;
}

}

@media only screen and (max-width: 807px) {
	.contactDetailsTemplate {
	-webkit-transform: translateY(-60%);
	        transform: translateY(-60%);
	opacity: 0;
	width: 50%;
	margin: 4.5rem 0 0 3rem;
	-webkit-animation-name: emergeDetails;
	        animation-name: emergeDetails;
	-webkit-animation-duration: 0.7s;
	        animation-duration: 0.7s;
	-webkit-animation-delay: 2s;
	        animation-delay: 2s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	letter-spacing: 0.1rem;
}

.contactTemplate {
	background-color: black;
	color: white;
	-webkit-filter: brightness(0%);
	        filter: brightness(0%);
	font-family: 'Teko', sans-serif;
	-webkit-animation-name: dimAndZoom;
	        animation-name: dimAndZoom;
	-webkit-animation-delay: 0.5s;
	        animation-delay: 0.5s;
	-webkit-animation-duration: 2s;
	        animation-duration: 2s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;	
}

.contactDiv {
	margin: 1.5rem auto;
	font-size: 1rem;
}

.contactDiv h3 {
	font-size: 1.5rem;
}

.contactBanner {
	padding-top: 1.5rem;
	background-color: black;
	height: 100vh;
	background-position: 60% 0%;
	background-size: cover;
	color: white;
}

.contactHeader {
	text-align: left;
	font-size: 2rem;
}

}

@media only screen and (max-width: 549px) {

.contactBanner {
	padding-top: 1.5rem;
	background-color: black;
	height: 100vh;
	background-position: 70% 0%;
	background-size: cover;
	color: white;
}

}


@media only screen and (max-width: 487px) {
.contactHeader {
	text-align: center;
	font-size: 2rem;
	margin-bottom: 0.5rem;
}

.contactBanner {
	background-color: black;
	padding-top: 1.5rem;
	height: 100vh;
	background-position: 70% 0%;
	color: white;
}	

.contactDetailsTemplate {
	-webkit-transform: translateY(-60%);
	        transform: translateY(-60%);
	opacity: 0;
	width: 90%;
	text-align: center;
	margin: 2rem auto;
	-webkit-animation-name: emergeDetails;
	        animation-name: emergeDetails;
	-webkit-animation-duration: 0.7s;
	        animation-duration: 0.7s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	letter-spacing: 0.1rem;
	-webkit-animation-delay: 0.5s;
	        animation-delay: 0.5s;
}

.contactTemplate {
	background-color: black;
	color: white;	
}

.contactInfo {
	line-height: 2rem;
	font-size: 1.2rem;
}

.contactDiv h3 {
	font-size: 1.7rem;
	line-height: 2rem;
}

}
.desktopNavDiv {
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0.5rem 2rem;
	background-color: black;
	color: white;
	letter-spacing: 0.1rem;
	font-family: 'Teko', sans-serif;			
	justify-content: space-between;
	font-size: 1.2rem;
	position: fixed;
	z-index: 500000;
	top: 0;
}

.desktopNavContainer {
	position: relative;
}

.extendMobileNav {
	padding-top: 3rem;
	position: absolute;
	z-index: 5000000;
	height: 100vh;
	width: 100%;
	letter-spacing: 0.2rem;
	background-color: black;
	color: white;
	font-family: 'Teko', sans-serif;	
	font-size: 1.5rem;
}

.defaultHide {
	left: -100%;
}

.defaultShow {
	left: 0;
}

.showMobileNav {
	-webkit-animation-name: slideMobileNavRight;
	        animation-name: slideMobileNavRight;
	-webkit-animation-duration: 1s;
	        animation-duration: 1s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;	
}

.hideMobileNav {
	-webkit-animation-name: slideMobileNavLeft;
	        animation-name: slideMobileNavLeft;
	-webkit-animation-duration: 1s;
	        animation-duration: 1s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

.mobileNavLinks {
	padding: 0;
	width: 80%;
	margin: 6rem auto 0;
	list-style-type: none;
}

.mobileNavLinks li {
	cursor: pointer;
	width: 100%;
	text-align: center;
	margin: 2.5rem auto;
	-webkit-transition: 1s all;
	transition: 1s all;
}


.mobileNavLinks li:hover {
	color: #009A56;	
}


.mm-tab {
	display: inline-block;
	margin-right: 1rem;
	left: 0;
}

.mobileNav {
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0.5rem 1rem;
	background-color: black;
	color: white;
	font-family: 'Teko', sans-serif;			
	justify-content: space-between;
	font-size: 1.2rem;
	height: 10vh;
	position: fixed;
	z-index: 700000000;
	top: 0;
	display: none;	
}

.mobileMenu {
	width: 85%;
	display: flex;
	align-items: center;
	text-align: left;
	position: relative;
	font-size: 1.6rem;
}

.mobileLogo {
	width: 15%;
	margin-right: 10px;
}

.mobileLogo img {
	height: 40px;
	width: 40px;
	margin: auto;
}

.mobileCart {
	margin-left: 3rem;
	cursor: pointer;
}

.navLinks {
	width: 95%;
}

.navLinks ul {
	letter-spacing: 0.2rem;
	width: 100%;
	list-style-type: none;
	display: flex;
	justify-content: space-around;
}

.navLinks ul li {
	margin: 0 0.5rem;
	cursor: pointer;
	-webkit-transition: 1s all;
	transition: 1s all;
}

.navLinks ul li:hover, .activeLink {
	color: #01CC01;
}

.menuTab {
	cursor: pointer;
	-webkit-transition: 1s all;
	transition: 1s all;
}

.menuTab:hover {
	color: gold;
}

.navLogo {
	width: 5%;
	margin: 0 1rem;
}

.navLogo img {
	cursor: pointer;
	height: 50px;
	width: 70px;
	margin: auto;
}

.desktopLinks {
	list-style-type: none;
	margin: 0 auto;
	display: flex;
	width: 70%;
	justify-content: center;
	font-weight: lighter;
}

.desktopLinks li {
	margin: 0 0.5rem;
}


img {
	width: 100%;
}

@-webkit-keyframes slideMobileNavRight {
	from {left: -100%;}
	to {left: 0;}
}

@keyframes slideMobileNavRight {
	from {left: -100%;}
	to {left: 0;}
}

@-webkit-keyframes slideMobileNavLeft {
	from {left: 0;};
	to {left: -100%;}
}

@keyframes slideMobileNavLeft {
	from {left: 0;};
	to {left: -100%;}
}	


@media only screen and (max-width: 900px){
.navLinks ul {
	letter-spacing: 0.1rem;
	width: 100%;
	list-style-type: none;
	display: flex;
	justify-content: space-between;
}
}

@media only screen and (max-width: 790px) {
.desktopNavDiv {
	display: none;
}

.navLogo {
	display: none;
}

.mobileNav {
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0.5rem 1rem;
	background-color: black;
	color: white;
	font-family: 'Teko', sans-serif;			
	justify-content: space-between;
	font-size: 1.2rem;
	height: 8vh;
	position: fixed;
	z-index: 700000000;
	top: 0;
}


}

@media only screen and (max-width: 373px) {
	.mobileCart {
		margin-left: 1.2rem
	}

.extendMobileNav {
	padding-top: 3rem;
	position: absolute;
	z-index: 5000000;
	height: 100vh;
	width: 100%;
	letter-spacing: 0.2rem;
	background-color: black;
	color: white;
	font-family: 'Teko', sans-serif;	
	font-size: 1.5rem;
}

.mobileNavLinks li {
	cursor: pointer;
	width: 100%;
	text-align: center;
	margin-top: 2.5rem;
	-webkit-transition: 1s all;
	transition: 1s all;
}

}
.DesktopBanner {
	width: 100%;
	height: 95vh;
	background-image: url(/static/media/homeBanner.404517df.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	-webkit-animation-name: lowerBrightness;
	        animation-name: lowerBrightness;
	-webkit-animation-duration: 3s;
	        animation-duration: 3s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

.bannerText {
	position: absolute;
	width: 100%;
	margin: auto;
	height: 400px;
	top: 45%;
}

.sigText {
	width: 100%;
	display: block;
	text-align: center;
	font-size: 0.7rem;
	margin-top: 4px;
	cursor: pointer;
}

.strong {
	letter-spacing: 0.1px;
}

.introText p {
	font-size: 0.9rem;
	line-height: 20px;
}


.bannerHeading {
	width: 100%;
	text-align: center;
	margin: 1rem auto 3rem;
	letter-spacing: 0.1rem;
	display: block;
	font-family: 'Teko', sans-serif;
	font-size: 2.2rem;
	color: white;
	-webkit-filter: brightness(100%);
	        filter: brightness(100%);
	opacity: 0;
	-webkit-animation-name: appearFromTop;
	        animation-name: appearFromTop;
	-webkit-animation-delay: 0.5s;
	        animation-delay: 0.5s;
	-webkit-animation-duration: 1s;
	        animation-duration: 1s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

 h1 {
 	padding-bottom: 0.5rem;
	margin-bottom: 1rem;
	font-size: 1rem;
	letter-spacing: 0.1rem;
}

.bannerBtn {
	width: 80%;
	margin: auto;
	color: white;
	text-shadow: 6px 6px 6px black;
	display: block;
	font-family: 'Teko', sans-serif;
	font-weight: 600;
	font-size: 6.2rem;
	text-align: center;
	-webkit-transition: 1s all;
	transition: 1s all;
	opacity: 0;
	-webkit-animation-name: appearFromBottom;
	        animation-name: appearFromBottom;
	-webkit-animation-delay: 1s;
	        animation-delay: 1s;
	-webkit-animation-duration: 1s;
	        animation-duration: 1s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;	
}

section {
	text-align: center;
	color: black;
	width: 65%;
	margin: 1.5rem auto;
}

section h1 {
	display: inline-block;
	width: 100%;
	text-align: center;
	font-size: 1.1rem;
}

.essentials {
	margin: 1rem auto;
	display: flex;
	justify-content: space-between;
	text-align: center;
	width: 100%;
}

.product-image-details {
	width: 25%;
	margin: 0 0.5rem;
}

.product-image-details img {
	width: 100%;
}

.mainServices {
	margin: 0;
	display: flex;
	align-items: stretch;
}

.introText {
	text-align: center;
	color: white;
	width: 50%;
	margin: auto;
}

.mainIntro {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 650px;
	width: 100%;
	margin: 0;
	background-image: url(/static/media/introWallpaper2.60247934.JPG);
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	background-repeat: no-repeat;
	position: relative;
}

.services-img {
	width: 50%;
	background-color: black;
}

.services-img img {
	object-fit: contain;
	width: 100%;
	height: 100%;
}

.servicesInfo {
	background-color: black;
	color: #dbdbdb;	
	width: 50%;
	padding: 0 5rem;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

li {
	text-transform: capitalize;
}

.introDiv {
	background-color: black;
	color: white;
}

.servicesInfo ul {
	list-style-type: none;
}

.contentBtn {
    font-family: 'Noto Sans KR', sans-serif;	
	margin: 1.2rem auto;
	border: 1px solid white;
	width: 75%;
	font-size: 0.9rem;
	padding: 0.2rem 1rem;
	background-color: black;
	color: white;
	-webkit-transition: 1s all;
	transition: 1s all;
}

.contentBtn:hover {
	background-color: #009A56;
	color: black;
}

@-webkit-keyframes lowerBrightness {
	from {-webkit-filter: brightness(100%);filter: brightness(100%);}
	to {-webkit-filter: brightness(40%);filter: brightness(40%);}
}

@keyframes lowerBrightness {
	from {-webkit-filter: brightness(100%);filter: brightness(100%);}
	to {-webkit-filter: brightness(40%);filter: brightness(40%);}
}

@-webkit-keyframes appearFromTop {
	from {opacity: 0; -webkit-transform: translateY(-2000px); transform: translateY(-2000px);}
	to {opacity: 0.9; -webkit-transform: translateY(0px); transform: translateY(0px);}
}

@keyframes appearFromTop {
	from {opacity: 0; -webkit-transform: translateY(-2000px); transform: translateY(-2000px);}
	to {opacity: 0.9; -webkit-transform: translateY(0px); transform: translateY(0px);}
}

@-webkit-keyframes appearFromBottom {
	from {opacity: 0; -webkit-transform: translateY(300px); transform: translateY(300px);}
	to {opacity: 0.9; -webkit-transform: translateY(0px); transform: translateY(0px);}	
}

@keyframes appearFromBottom {
	from {opacity: 0; -webkit-transform: translateY(300px); transform: translateY(300px);}
	to {opacity: 0.9; -webkit-transform: translateY(0px); transform: translateY(0px);}	
}


@media only screen and (max-width: 1104px) {
.servicesInfo {
	background-color: black;
	color: #dbdbdb;	
	width: 50%;
	padding: 0 1.5rem;
	font-size: 0.7rem;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
}	
}

@media only screen and (max-width: 998px) {
.bannerBtn {
	cursor: pointer;
	width: 50%;
	margin: 1.5rem auto;
	color: white;
	display: block;
	font-family: 'Teko', sans-serif;	
	font-weight: 600;
	font-size: 2.8rem;
	padding: 1rem;	
	text-align: center;
	-webkit-transition: 1s all;
	transition: 1s all;
	opacity: 0;
	-webkit-animation-name: appearFromBottom;
	        animation-name: appearFromBottom;
	-webkit-animation-delay: 1.5s;
	        animation-delay: 1.5s;
	-webkit-animation-duration: 1.2s;
	        animation-duration: 1.2s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;	
}

}

@media only screen and (max-width: 950px) {

.essentials {
	margin: 1rem auto;
	display: flex;
	justify-content: space-between;
	text-align: center;
	width: 100%;
}

.mainServices {
	margin: 0;
	display: flex;
	flex-direction: column;

}	

.services-img {
	width: 100%;
	background-color: black;
}

.services-img img {
	overflow: hidden;
}


.servicesInfo {
	background-color: black;
	color: #dbdbdb;	
	width: 100%;
	padding: 1rem 8rem;
	font-size: 0.8rem;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.order1 {
	order: 1;
}

.order2 {
	order: 2;
	margin: 0;
}

}


@media only screen and (max-width: 712px) {


.bannerBtn {
	cursor: pointer;
	width: 65%;
	margin: 1.5rem auto;
	color: white;
	display: block;
	font-family: 'Teko', sans-serif;	
	font-weight: 600;
	font-size: 2.2rem;
	padding: 2rem;	
	text-align: center;
	-webkit-transition: 1s all;
	transition: 1s all;
	opacity: 0;
	-webkit-animation-name: appearFromBottom;
	        animation-name: appearFromBottom;
	-webkit-animation-delay: 1.5s;
	        animation-delay: 1.5s;
	-webkit-animation-duration: 1.2s;
	        animation-duration: 1.2s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;	
}
	
}

@media only screen and (max-width: 764px) {

section {
	background-color: #FFFFFF;
	color: #848b8e;
	text-align: justify;
}


.servicesInfo {
	background-color: black;
	color: #dbdbdb;	
	width: 100%;
	line-height: 1.4rem;
	padding: 0.2rem 2rem;
	font-size: 0.8rem;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
}	

.services-img {
	width: 100%;
	background-color: black;
}

}

@media only screen and (max-width: 640px) {
.servicesInfo {
	background-color: black;
	color: #dbdbdb;	
	width: 100%;
	min-height: 350px;
	line-height: 1.4rem;
	padding: 0.5rem 2rem;
	font-size: 0.8rem;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.mainIntro {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 550px;
	width: 100%;
	margin: 0;
	background-image: url(/static/media/introWallpaper2Mobile.a45a91d7.JPG);
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	background-repeat: no-repeat;
	position: relative;
}

.introText {
	text-align: center;
	color: white;
	width: 90%;
	margin: auto;
}

.hideSentence {
	display: none;
}

}

@media only screen and (max-width: 592px) {

.mainIntro {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 500px;
	width: 100%;
	padding: 25px auto;
	background-image: url(/static/media/missionstatementmobile.9f23b694.jpg);
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	background-repeat: no-repeat;
	position: relative;
}

.DesktopBanner {
	width: 100%;
	height: 95vh;
	background-image: url(/static/media/homebannermobile.2d23fb6d.jpg);
	background-size: cover;
	background-position: center;
	-webkit-animation-name: lowerBrightness;
	        animation-name: lowerBrightness;
	-webkit-animation-duration: 3s;
	        animation-duration: 3s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}


.bannerBtn {
	cursor: pointer;
	width: 85%;
	margin: 1.5rem auto;
	color: white;
	display: block;
	font-family: 'Teko', sans-serif;	
	font-weight: 600;
	font-size: 2.2rem;
	padding: 1rem;	
	text-align: center;
	-webkit-transition: 1s all;
	transition: 1s all;
	opacity: 0;
	-webkit-animation-name: appearFromBottom;
	        animation-name: appearFromBottom;
	-webkit-animation-delay: 1.5s;
	        animation-delay: 1.5s;
	-webkit-animation-duration: 1.2s;
	        animation-duration: 1.2s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;	
}

}

@media only screen and (max-width: 416px) {

.bannerText {
	position: absolute;
	width: 100%;
	margin: auto;
	height: 400px;
	top: 50%;
}


.bannerHeading {
	width: 100%;
	text-align: center;
	margin: 1rem auto;
	letter-spacing: 0.1rem;
	display: block;
	font-family: 'Teko', sans-serif;
	font-size: 1.7rem;
	color: white;
	-webkit-filter: brightness(100%);
	        filter: brightness(100%);
	opacity: 0;
	-webkit-animation-name: appearFromTop;
	        animation-name: appearFromTop;
	-webkit-animation-delay: 0.3s;
	        animation-delay: 0.3s;
	-webkit-animation-duration: 1.2s;
	        animation-duration: 1.2s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

.bannerBtn {
	cursor: pointer;
	width: 90%;
	margin: 1rem auto;
	color: white;
	font-size: 2rem;
	display: block;
	font-family: 'Teko', sans-serif;	
	font-weight: 600;	
	text-align: center;
	-webkit-transition: 1s all;
	transition: 1s all;
	opacity: 0;
	-webkit-animation-name: appearFromBottom;
	        animation-name: appearFromBottom;
	-webkit-animation-delay: 0.7s;
	        animation-delay: 0.7s;
	-webkit-animation-duration: 1.2s;
	        animation-duration: 1.2s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;	
}

}

@media only screen and (max-width: 390px) {
.contentBtn {
    font-family: 'Noto Sans KR', sans-serif;	
	margin: 1.2rem auto;
	border: 1px solid white;
	width: 90%;
	font-size: 0.8rem;
	padding: 0.5rem 1rem;
	background-color: black;
	color: white;
	-webkit-transition: 1s all;
	transition: 1s all;
}
}

.walkInServicesTemplate {
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-image: url(/static/media/childrenservices.a2e426cc.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.walkInServicesTemplateKids {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100vh;
	width: 100%;
	background-image: url(/static/media/lbBanner.72b3802a.jpg);
	background-size: cover;
	background-position: center top;
	background-repeat: no-repeat;
}

.walkInservicesDiv {
	line-height: 1.8rem;
	text-align: center;
	background-color: black;
	color: white;
	width: 100%;
	-webkit-animation-name: slideUp;
	        animation-name: slideUp;
	-webkit-animation-duration: 1.5s;
	        animation-duration: 1.5s;
	-webkit-animation-delay: 1s;
	        animation-delay: 1s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	opacity: 0;
	height: 100vh;
	font-size: 1.8rem;
	padding-top: 3rem;
	font-family: 'Teko', sans-serif;
}

.walkInservicesDiv h2 {
	border-bottom: 1px solid white;
	padding-bottom: 1rem;
	width: 45%;
	margin: 3rem auto 2rem;
}

.walkInservicesDiv p {
	font-size: 1.4rem;
	line-height: 25px;
}

@-webkit-keyframes slideUp {
	from {opacity: 0; -webkit-transform: translateY(500px); transform: translateY(500px);}
	to {opacity: 0.9; -webkit-transform: translateY(0); transform: translateY(0);}
}

@keyframes slideUp {
	from {opacity: 0; -webkit-transform: translateY(500px); transform: translateY(500px);}
	to {opacity: 0.9; -webkit-transform: translateY(0); transform: translateY(0);}
}

@media only screen and (max-width: 1090px) {
.walkInservicesDiv h2 {
	border-bottom: 1px solid white;
	padding-bottom: 1rem;
	width: 60%;
	margin: 2.5rem auto 0;
}	
}

@media only screen and (max-width: 861px) {
.walkInservicesDiv h2 {
	border-bottom: 1px solid white;
	padding-bottom: 1rem;
	width: 80%;
	margin: 2.5rem auto 0;
}	
}

@media only screen and (max-width: 667px) {
.walkInservicesDiv {
	line-height: 1.8rem;
	text-align: center;
	background-color: black;
	padding: 3rem;
	color: white;
	width: 100%;
	position: absolute;
	-webkit-animation-name: slideUp;
	        animation-name: slideUp;
	-webkit-animation-duration: 1.5s;
	        animation-duration: 1.5s;
	-webkit-animation-delay: 1s;
	        animation-delay: 1s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	opacity: 0;
	height: 99vh;
	font-size: 1.3rem;
	font-family: 'Teko', sans-serif;
}	
}

@media only screen and (max-width: 509px) {
.walkInservicesDiv h2 {
	border-bottom: 1px solid white;
	padding-bottom: 1rem;
	width: 95%;
	margin: 2.5rem auto 0;
}	

.walkInServicesTemplateKids {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100vh;
	width: 100%;
	background-image: url(/static/media/kids-services-mobile.3907a818.jpg);
	background-size: cover;
	background-repeat: no-repeat;
}

.walkInServicesTemplate {
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-image: url(/static/media/kidsServicesMobile.48abcf69.jpg);
	background-size: cover;
	background-repeat: no-repeat;
}

}

@media only screen and (max-width: 437px) {
.walkInservicesDiv {
	line-height: 1.5rem;
	text-align: center;
	background-color: black;
	padding: 2rem;
	color: white;
	width: 100%;
	position: absolute;
	-webkit-animation-name: slideUp;
	        animation-name: slideUp;
	-webkit-animation-duration: 1.5s;
	        animation-duration: 1.5s;
	-webkit-animation-delay: 1s;
	        animation-delay: 1s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	opacity: 0;
	height: 99vh;
	font-size: 1.3rem;
	font-family: 'Teko', sans-serif;
}	
}
.beyondTheChair {
	background-image: url(/static/media/btbcbanner.da70afd4.jpg);
	background-size: cover;
	background-position: center;
	height: 500px;
	width: 100%;
}

.galleryContainer {
	width: 100%;
	margin: 35px auto;
	display: flex;
	justify-content: space-between;
}

.mobileImg {
	display: none;
	width: 100%;
	margin: 10px auto;
}

.highlightImg {
	min-width: 24%;
	margin: auto 5px;
	width: 24%;
}

.btcCaption {
	width: 100%;
	text-align: center;
}

.textOne, .textTwo {
	margin: 30px auto;
	text-shadow: 4px 4px 4px black;
	color: white;
	display: block;
	font-family: 'Teko', sans-serif;
	font-size: 7rem;
	letter-spacing: 5px;
	opacity: 0;
}

.btcContainer img {
	border: 6px solid white;
}

.bytcHeading {
	text-align: center;
	color:  white;
}

.textOne {
	-webkit-animation-name: arriveFromRight;
	        animation-name: arriveFromRight;
	-webkit-animation-delay: 2s;
	        animation-delay: 2s;
	-webkit-animation-duration: 1s;
	        animation-duration: 1s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

.textTwo {
	-webkit-animation-name: arriveFromLeft;
	        animation-name: arriveFromLeft;
	-webkit-animation-delay: 2s;
	        animation-delay: 2s;
	-webkit-animation-duration: 1s;
	        animation-duration: 1s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

.btcText {
	width: 80%;
	margin: 50px auto;
	display: flex;
	justify-content: space-around;

}

.articleContent {
	width: 75%;
	margin: 10px auto;
	text-align: center;
}

.articleImg {
	width: 75%;
	margin: 20px auto;
	display: flex;
	justify-content: space-between;
}

.articleSingleImg {
	width: 45%;
}

.articleSingleImg img {
	width: 100%;
}

.btcHeading {
	font-weight: bold;
	font-size: 0.9rem;
	text-transform: uppercase;
	letter-spacing: 1px;
	width: 100%;
	text-align: center;
	margin: 0.5px auto;
	padding-bottom: 15px;
	border-bottom: 1px solid #b5b5b5;;
}

.btc-info {
	text-align: justify;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 45%;
	margin: auto 10px;
}


.btcImg {
	margin: auto 10px;
	width: 45%;
}

.socialMedia {
	display: inline-block;
	width: 10%;
	margin: auto 15px;
}

.youTubeList {
	border-top: 1px solid #161616;
	width: 80%;
	margin: auto;
	position: relative;
}

.ytHeading {
	margin-top: 30px;
	width: 100%;
	text-align: center;
}

.videoContainer {
	width: 100%;
	margin: 10px auto;
	position: relative;
 	padding-top: 56.25%	
}

.videoDiv {
position: absolute;
  top: 0;
  left: 0;
}

.youTubeListTablet {
	width: 50%;
	margin: auto;
}

@-webkit-keyframes arriveFromRight {
	from {-webkit-transform: translateX(-150px);transform: translateX(-150px); opacity: 0;}
	to {-webkit-transform: translateY(0);transform: translateY(0); opacity: 1;}
}

@keyframes arriveFromRight {
	from {-webkit-transform: translateX(-150px);transform: translateX(-150px); opacity: 0;}
	to {-webkit-transform: translateY(0);transform: translateY(0); opacity: 1;}
}

@-webkit-keyframes arriveFromLeft {
	from {-webkit-transform: translateX(150px);transform: translateX(150px); opacity: 0;}
	to {-webkit-transform: translateY(0);transform: translateY(0); opacity: 1;}
}

@keyframes arriveFromLeft {
	from {-webkit-transform: translateX(150px);transform: translateX(150px); opacity: 0;}
	to {-webkit-transform: translateY(0);transform: translateY(0); opacity: 1;}
}


@media only screen and (max-width: 1054px) {
.btcText {
	width: 90%;
	margin: 60px auto;
	display: flex;
	justify-content: space-around;
	color: white;

}
}

@media only screen and (max-width: 836px) {
.btcText {
	width: 75%;
	margin: 60px auto;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	color: white;

}

.galleryContainer {
	width: 100%;
	margin: 5px auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.highlightImg {
	width: 46%;
	margin: 5px;
}

.text-right {
	order: 1;
}

.btc-info {
	text-align: justify;
	width: 80%;
	margin: auto;
}


.btcImg {
	margin: 20px auto;
	width: 80%;
}

}


@media only screen and (max-width: 836px) {
.btcText {
	width: 95%;
	margin: 40px auto;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	color: white;

}

.btc-info {
	text-align: justify;
	width: 80%;
	margin: auto;
}


.btcImg {
	margin: 20px auto;
	width: 80%;
}

.textOne, .textTwo {
	margin: 30px auto;
	text-shadow: 4px 4px 4px black;
	color: white;
	display: block;
	font-family: 'Teko', sans-serif;
	font-size: 4rem;
	letter-spacing: 5px;
	opacity: 0;
}


}

@media only screen and (max-width: 716px) {

.beyondTheChair {
	background-image: url(/static/media/btbcbanner.da70afd4.jpg);
	background-size: cover;
	background-position: right;
	height: 420px;
	width: 100%;
}

}

@media only screen and (max-width: 456px) {
.btcText {
	width: 100%;
	margin: 25px auto;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	color: white;

}

.beyondTheChair {
	background-image: url(/static/media/btbcbanner.da70afd4.jpg);
	background-size: cover;
	background-position: 85% 80%;
	height: 350px;
	width: 100%;
}

.galleryContainer {
	width: 100%;
	margin: 5px auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.highlightImg {
	min-width: 90%;
	margin: 15px auto;
}

.mobileImg {
	display: inline;
	display: initial;
}

.textOne, .textTwo {
	margin: 10px auto;
	text-shadow: 4px 4px 4px black;
	color: white;
	display: block;
	font-family: 'Teko', sans-serif;
	font-size: 2.5rem;
	letter-spacing: 5px;
	opacity: 0;
}

.btc-info {
	text-align: justify;
	width: 80%;
	margin: auto;
}


.btcImg {
	margin: 20px auto;
	width: 80%;
}

}
.mobileLookBook {
	width: 85%;
	margin: 15px auto;
	display: none;
}

.lbMImg {
	width: 100%;
	margin: 25px auto;
}

.lbMImg img {
	display: block;
	height: 320px;
	width: 270px;
	object-fit: cover;
	object-position: top;
	border: 6px solid white;
}

.pPricing {
	display: block;
	width: 100%;
	text-align: center;
}

.tpMainContainer {
	background-color: black;
	color: white;
	position: relative;
	background: black;
}

.lookBookMainContainer {
	background-color: black;
	color: white;
	padding-bottom: 20px;
}

.mIContainer {
	width: 80%;
	margin: auto;
	background-color: black;
	flex-direction: column;
	justify-content: center;
	align-items: center;	
}

.hideVisiblity {
	visibility: none;
}

.lbContainer {
	width: 80%;
	margin: auto;
}

.mobileTPImg {
	display: none;
}

.mainImage {
	width: 80%;
	margin: auto;
	height: 500px;
	padding-bottom: 30px;
}

.closeImg {
	letter-spacing: 1px;
	text-decoration: underline;
	margin: 30px auto;
	display: block;
	width: 100%;
	text-align: center;
	font-size: 0.8rem;
	cursor: pointer;
}

.mainImage img {
	width: 80%;
	height: 100%;
	display: block;
	object-fit: contain
}

.piercingPriceList {
	width: 70%;
	margin: 25px auto;
	border: 0.5px solid white;
}

.listContainer {
	display: flex;
	border-top: 1px white solid;
}

.pplTypeList, .pplCostList {
	width: 50%;
}

.pplCostList {
	border-left: 0.5px solid white; ;
}

.tableText {
	display: block;
	width: 100%;
	text-align: center;
	padding: 5px;
}

.pplHeader {
 	display: flex;
 	width: 100%;
 	border-bottom: 0.5px solid white;
}

.pplServiceType, .pplPrice {
	width: 50%;
}

.pplPrice {
	border-left: 1px solid black;
}

.pplHeadSection {
	padding: 5px;
	display: block;
	width: 100%;
	text-align: center;
	font-weight: bold;
	letter-spacing: 0.8px;
	background-color: white;
	color: black;
}

.hideContent {
	display: hide;
}

.showContent {
	display: inline;
	display: initial;
}

.lookBookBanner {
	background-image: url(/static/media/lookbookwallpaper.1da639a3.jpg);
	background-size: cover;
	background-position: center top;
	height: 500px;
	width: 100%;
}

.tatooBanner {
	background-image: url(/static/media/tatooBanner.e558ab64.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	height: 450px;
	width: 100%;
	margin-bottom: 30px;
}

.pGallery {
	width: 80%;
	margin: auto;
	padding-bottom: 30px;
}

.pRow {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.tandpcontainer {
	width: 60%;
	display: flex;
	margin: 60px auto;
	justify-content: space-around;
}

.tatooImg {
	width: 50%;
	margin: 10px 30px;
	box-shadow: 10px 10px 10px gray;
}

.tatooImg img {
	width: 100%;
}

.imgContainer {
	margin: 10px 5px;
	-webkit-transition: 1s all;
	transition: 1s all;
	cursor: pointer;
	width: 15%;
	flex-grow: 1;
	border: 6px solid white;
}

.lookBookIntro {
	width: 100%;
	margin: 15px auto;
	text-align: center;
}

.lookBookDesktopSection {
	margin: 30px auto;
	width: 85%;
	display: flex;
}

.showImgContainer {
	margin: auto;
	width: 40%;
	padding-top: 60px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;	
}

.hideImgContainer {
	display: none;
	margin: 40px auto;
	width: 40%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
}

.arrows {
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	width: 5%;
	color: gray;
	font-size: 1.5rem;
}

.closePimage {
	font-size: 1.2rem;
	display: block;
	width: 95%;
	margin: 10px auto;
	text-align: right;
}

.selectedImgContainer {
	width: 50%;
	margin: auto;
}

.selectedImg {
	width: 100%;
	margin: 10px auto;
	border: 10px white solid;
}

.slideShow {
	width: 90%;
	overflow: hidden;
}

.slider {
	-webkit-transition: 0.5s all;
	transition: 0.5s all;
	width: 600%;
	display: flex;
	justify-content: space-between;
	/*increase by 16.6*/
}


.slider1 {
	-webkit-transition: 0.5s all;
	transition: 0.5s all;
	width: 600%;
	display: flex;
	-webkit-transform: translateX(-16.6%);
	        transform: translateX(-16.6%);	
}

.slider2 {
	-webkit-transition: 0.5s all;
	transition: 0.5s all;
	width: 600%;
	display: flex;
	-webkit-transform: translateX(-33.2%);
	        transform: translateX(-33.2%);	
}

.slider3 {
	-webkit-transition: 0.5s all;
	transition: 0.5s all;
	width: 600%;
	display: flex;
	-webkit-transform: translateX(-49.8%);
	        transform: translateX(-49.8%);	
}

.slider4 {
	-webkit-transition: 0.5s all;
	transition: 0.5s all;
	width: 600%;
	display: flex;
	-webkit-transform: translateX(-66.4%);
	        transform: translateX(-66.4%);	
}

.slider5 {
	-webkit-transition: 0.5s all;
	transition: 0.5s all;
	width: 600%;
	display: flex;
	-webkit-transform: translateX(-83%);
	        transform: translateX(-83%);	
}

.slider6 {
	-webkit-transition: 0.5s all;
	transition: 0.5s all;
	width: 600%;
	display: flex;
	-webkit-transform: translateX(-83%);
	        transform: translateX(-83%);	
}

.sliderFrame {
	margin: auto;
	width: 16%;
	display: flex;
	justify-content: space-around;
}

.slideShow img {
	width: 25%;
	margin: auto 10px;
	border: 6px solid white;
}

.lookBookMobile {
	width: 90%;
	margin: 10px auto;
	display: none;
}

.lookBookMobile img {
	width: 85%;
	margin: 10px auto;
}

.lookBookContainer {
	width: 85%;
	margin: 25px auto;
	display: flex;
	justify-content: space-around;
}

.lookBookContainer img {	
	width: 45%;
	box-shadow: 3px 3px 3px gray;
	margin: 5px;
}


.tatooGallery {
	width: 85%;
	margin: 20px auto;
}

.galleryImg {
	width: 85%;
	margin: 20px auto;
	display: flex;
	justify-content: space-around;
}

.galleryImg img {
	box-shadow: 10px 10px 10px gray;
	margin: auto 10px;
}

@media only screen and (max-width: 888px) {

.imgContainer {
	margin: 10px 5px;
	-webkit-transition: 1s all;
	transition: 1s all;
	cursor: pointer;
	min-width: 30%;
	flex-grow: 1;
	border: 6px solid white;
}

.desktopImg {
	display: none;
}

.mobileTPImg {
	display: inline;
	display: initial;
}

}


@media only screen and (max-width: 800px) {
	.lookBookBanner {
	background-image: url(/static/media/lookbookwallpaper.1da639a3.jpg);
	background-size: cover;
	background-position: center top;
	height: 300px;
	width: 100%;
}
}

@media only screen and (max-width: 700px) {

.lookBookDesktopSection {
	display: none;
}

.mobileLookBook {
	display: inline;
	display: initial;
}

.piercingPriceList {
	width: 90%;
	margin: 25px auto;
	border: 0.5px solid white;
}

}

@media only screen and (max-width: 536px) {

.tatooBanner {
	background-image: url(/static/media/tatooWallpaper2.66bfe746.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	height: 380px;
	width: 100%;
}

.lookBookBanner {
	background-image: url(/static/media/lookbookwallpapermobile.c3725cd4.jpg);
	background-size: cover;
	background-position: center;
	height: 400px;
	width: 100%;
}

.piercingPriceList {
	width: 100%;
	margin: 25px auto;
	border: 0.5px solid white;
}

.imgContainer {
	-webkit-transition: 1s all;
	transition: 1s all;
	width: 45%;
	flex-grow: 1;
	border: 6px solid white;
}


.pGallery {
	width: 90%;
	margin: auto;
	padding-bottom: 30px;
}

}

@media only screen and (max-width: 390px) {

.lookBookDesktopSection {
	display: none;
}

.lookBookMobile {
	width: 90%;
	margin: 10px auto;
	display: inline;
	display: initial;
}


.imgContainer {
	margin: 5px;
	-webkit-transition: 1s all;
	transition: 1s all;
	width: 90%;
	flex-grow: 1;
	border: 6px solid white;
}

.pGallery {
	width: 90%;
	margin: auto;
	padding-bottom: 30px;
}


}
.recContainer {
	background-color: black;
	color: white;
	padding-bottom: 60px;
}

.boldFont {
	font-weight: bold;
}

.recBanner {
	width: 100%;
	height: 500px;
	background-image: url(/static/media/recBanner.95272d08.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	margin-bottom: 30px;
}

.joinIntro {
	color: white;
	text-align: center;
	width: 75%;
	margin: auto;
	display: flex;
	justify-content: space-between;
	background-color: black
}

.mobileRec {
	display: none;
	color: #3a3a3a;
	width: 75%;
	margin: auto;
	justify-content: space-between;	
}

.mobileJobSpec {
	list-style-type: none;
}

.mobileJobSpec li {
	margin: 2px auto;
}

.join-text ul {
	margin-left: 10px;
}

.join-text {
	width: 75%;
	text-align: left;
}

.jobTitle {
	 margin: 20px auto;
}

.join-positions {
	width: 20%;
	text-align: center;
	border: 0.5px solid white;
}

.join-positions ul {
	list-style-type: none;
}

.join-positions h3 {
	margin: 0 auto 10px;
	letter-spacing: 0.8px;
	background-color: white;
	color: black;
	font-weight: bold;
	padding: 5px;
}

.join-positions ul li {
	margin: 15px auto;
}


.jobTitle {
	font-size: 1.4rem;
	letter-spacing: 1px;
}

.applyLink {
	color: navy;
	border-bottom: dotted 1px black;
}


@media only screen and (max-width: 1134px) {

.joinIntro {
	color: #3a3a3a;
	text-align: center;
	width: 85%;
	margin: auto;
	display: flex;
	justify-content: space-between;
}

.join-positions {
	width: 20%;
	text-align: center;
	border: 0.5px solid lightgray;
	padding: 10px;
}

} 

@media only screen and (max-width: 900px) {
	.joinIntro {
	display: none;
}

	.mobileRec {
	display: block;
	color: white;
	width: 75%;
	margin: 10px auto;	
}

}

@media only screen and (max-width: 768px) {
.recBanner {
	width: 100%;
	height: 400px;
	background-image: url(/static/media/recBanner.95272d08.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	margin-bottom: 30px;
}	

}

@media only screen and (max-width: 768px) {
.recBanner {
	width: 100%;
	height: 300px;
	background-image: url(/static/media/recBanner.95272d08.jpg);
	background-size: cover;
	background-position: left;
	background-repeat: no-repeat;
	margin-bottom: 30px;
}	

}
.ProductTemplate {
	min-width: 30%;
	padding: 1.5rem 1rem 0.5rem;
	margin: 1rem 2rem;
	text-align: center;
	position: relative;
	height: 500px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

}

.ProductTemplate img {
	width: 80%;
	margin: auto;
	margin-bottom: 1rem;
}

.ProductTemplate h2 {
	display: block;
	text-align: center;
	border-top: 1px solid #d1d1d1;
	width: 100%;
	padding-top: 0.5rem;
	text-transform: capitalize;
	min-height: 50px;
	font-size: 0.8rem;
}


.productDescriptionPrice {
	min-height: 75px;
}

.productPrice {
	font-size: 0.8rem;
	font-style: bold;
}

.ProductTemplate button {
	width: 100%;
	font-family: 'Teko', sans-serif;	
	padding: 0.2rem 2rem;
	background-color: black;
	color: white;
	font-size: 1.2rem;
	letter-spacing: 0.1rem;
	border: none;
	display: block;
	margin: 0.5rem auto;
	-webkit-transition: 1s all;
	transition: 1s all;
}

.ProductTemplate button:hover {
		color: #B48B4C;
}


@media only screen and (max-width: 1090px) {
	.ProductTemplate {
	min-width: 30%;
	margin: 0.2rem 2rem;
	text-align: center;
	position: relative;
	height: 440px;
	display: flex;
	flex-direction: column;

}
}

@media only screen and (max-width: 1090px) {
	.ProductTemplate {
	min-width: 30%;
	margin: 0.2rem 2rem;
	text-align: center;
	position: relative;
	height: 480px;
	display: flex;
	flex-direction: column;

}
}

@media only screen and (max-width: 670px) {
	.ProductTemplate {
	min-width: 30%;
	margin: 0.2rem 2rem;
	text-align: center;
	position: relative;
	height: 450px;
	display: flex;
	flex-direction: column;

}
}

@media only screen and (max-width: 584px) {
	.ProductTemplate {
	min-width: 30%;
	margin: 0.2rem 2rem;
	text-align: center;
	position: relative;
	height: 580px;
	display: flex;
	flex-direction: column;

}
}

@media only screen and (max-width: 466px) {
	.ProductTemplate {
	min-width: 30%;
	margin: 0.2rem 2rem;
	text-align: center;
	position: relative;
	height: 510px;
	display: flex;
	flex-direction: column;

}
}

@media only screen and (max-width: 400px) {
	.ProductTemplate {
	min-width: 30%;
	margin: 0.2rem 2rem;
	text-align: center;
	position: relative;
	height: 440px;
	display: flex;
	flex-direction: column;

}
}

.basketHeading {
	margin: 1rem auto;
	display: flex;
	width: 85%;
	list-style-type: none;
	justify-content: space-between;
	border: 1px solid lightgray;
	padding: 1rem 6rem;
	font-family: 'Teko', sans-serif;
	font-size: 1.1rem;
	letter-spacing: 0.1rem;
	background-color: #f9f9f9;
	border: none;
}

.checkoutList li {
	width: 25%;
	text-align: center;
}

.checkoutList {
	margin: 1rem auto;
	display: flex;
	align-items: center;
	width: 85%;
	list-style-type: none;
	justify-content: space-between;
	padding: 0.5rem 4rem;
	font-family: 'Teko', sans-serif;
	font-size: 1rem;
	letter-spacing: 0.1rem;	
	border-bottom: 1px lightgray solid;
	box-shadow: 5px 5px 5px 5px gray;
}

input {
	display: block;
	margin: auto;
	width: 25%;
}

.checkoutList img {
	width: 70%;
}

.qty-change {
	margin: 1.5rem 0.5rem 0;
	padding: 0.3rem;
	color: white;
	font-size: 0.4rem;
	background-color: gray;
	text-align: center;
	cursor: pointer;
}

.subTotalCheckout {
	box-shadow: 5px 5px 6px 4px gray;
	width: 85%;
	cursor: pointer;
	background-color: black;
	display: flex;
	justify-content: center;
	color: white;
	padding: 1rem 2rem;
	margin: 1rem auto;
}

.subTotalCheckout span {
	margin: auto 3rem;
	letter-spacing: 0.1rem;
	display: block;
	width: 100%;
	text-align: center;
}

.responsiveLabel {
	display: none;
}

.deleteItem {
	display: block;
	margin: auto;
	color: red;
	width: 80%;
	cursor: pointer;
}

@media only screen and (max-width: 976px) {

.subTotalCheckout {
	width: 85%;
	cursor: pointer;
	background-color: black;
	display: flex;
	justify-content: center;
	color: white;
	padding: 1rem 2rem;
	margin: 1rem auto;
	-webkit-transition: 1s all;
	transition: 1s all;
}	

}

@media only screen and (max-width: 670px) {
	.checkoutList {
		display: flex;
		padding: 1rem;
		flex-direction: column;
		width: 90%;
		font-size: 1.2rem;
	}

.checkoutList li {
	margin: 0.3rem auto;
	width: 100%;
}

.checkoutList li img {
	width: 70%;
	border-bottom: 1px solid lightgray;
	padding-bottom: 1rem;
}

.responsiveLabel {
	display: inline;
	display: initial;
	color: black;
}

.basketHeading {
	display: none;
}

.subTotalCheckout {
	width: 90%;
	cursor: pointer;
	background-color: black;
	display: flex;
	justify-content: center;
	color: white;
	padding: 1rem 2rem;
	margin: 1rem auto;
}	

}

@media only screen and (max-width: 430px) {

.subTotalCheckout {
	width: 90%;
	cursor: pointer;
	font-size: 0.6rem;
	background-color: black;
	display: flex;
	justify-content: center;
	color: white;
	padding: 0.5rem;
	margin: 1rem auto;
}	

}
.bookerServiceContainer {
	width: 60%;
	margin: 6rem auto;
	border: 1px solid gray;
	color: lightgray;
	box-shadow: 6px 3px 5px gray;
	text-align: center;
	height: 500px;
	background-color: black;
	position: relative;
	overflow: hidden;

}

.basketContainer {
	margin-top: 5rem;
}

.responsiveAppointment {
	width: 80%;
	margin: 5rem auto;
	background-color: black;
	color: white;
	padding: 1rem;
	text-align: center;
	display: none;
}

.showContent {
	display: block;
}

.appHeading {
	display: block;
	width: 100%;
	text-align: center;
	margin: 0.5rem auto;
}

.responsiveBtn {
	width: 80%;
	font-family: 'Teko', sans-serif;	
	padding: 0.2rem 2rem;
	background-color: white;
	color: black;
	font-size: 1.2rem;
	letter-spacing: 0.1rem;
	border: none;
	display: block;
	margin: 1.5rem auto;
	-webkit-transition: 1s all;
	transition: 1s all;	
}

.hideContent {
	display: none;
}

.bookingSlideContent {
	height: 2500px;
	width: 100%;
	position: absolute;
}

.bookingSlideContentBarbers {
	height: 2500px;
	width: 100%;
	top: -500px;
	position: absolute;
}

.bookingSlideContentDate {
	height: 2500px;
	width: 100%;
	top: -1000px;
	position: absolute;
}

.bookingSlideContentDetails {
	height: 2500px;
	width: 100%;
	top: -1500px;
	position: absolute;
}


.goToBarbers {
	-webkit-animation-name: slideDown;
	        animation-name: slideDown;
	-webkit-animation-delay: 0.8s;
	        animation-delay: 0.8s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	-webkit-animation-duration: 1.3s;
	        animation-duration: 1.3s;
}

.goToDateTime {
	-webkit-animation-name: slideDownTwo;
	        animation-name: slideDownTwo;
	-webkit-animation-delay: 0.8s;
	        animation-delay: 0.8s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	-webkit-animation-duration: 1.3s;
	        animation-duration: 1.3s;
}

.goToDetails {
	-webkit-animation-name: slideDownThree;
	        animation-name: slideDownThree;
	-webkit-animation-delay: 0.8s;
	        animation-delay: 0.8s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	-webkit-animation-duration: 1.3s;
	        animation-duration: 1.3s;
}

.goToConfirmation {
	-webkit-animation-name: slideDownFour;
	        animation-name: slideDownFour;
	-webkit-animation-delay: 0.8s;
	        animation-delay: 0.8s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	-webkit-animation-duration: 1.3s;
	        animation-duration: 1.3s;
}


.dateTimeSelection {
	display: flex;
	width: 100%;
	margin: 1.5rem auto;
	justify-content: space-around;
}

.daySelection, .timeSelection {
	width: 45%;
	border: 0.5px solid gray;
}

.timeList {
	display: inline-block;
	width: 23%;
	cursor: pointer;
	margin: 0.5rem;
	-webkit-transition: 1s all;
	transition: 1s all;
}

.formNames {
	 margin: auto;
	width: 95%;
}

.formNames div {
	width: 50%;
	margin: 1rem auto;
}

.formNames input {
	margin: 0.2rem auto;
	width: 85%;
	padding: 0.3rem 0.5rem;
}

.dayList {
	cursor: pointer;
	display: block;
	width: 100%;
	border-bottom: 0.5px solid white;
	padding: 0.5rem;
	-webkit-transition: 0.5s all;
	transition: 0.5s all;
}

.dayList:hover, .timeList:hover, .selectedDateTime {
	background-color: #B48B4C;
	color: black;
}


.daySelection span:last-child {
	border-bottom: none;
}

.daySelection h3, .timeSelection h3 {
	background-color: gray;
	color: white;
	padding: 0.5rem;
}

.sliderDiv {
	padding: 1rem 2rem;
	width: 100%;
	background-color: black;
	height: 500px;
	position: relative;
}

.spanTag {
	display: block;
	margin: 0.5rem auto;
}

.checkBox {
	font-size: 0.7rem;
	width: 100%;
}

.checkBox input {
	display: inline-block;
	width: 3%;
}

.navBack {
	cursor: pointer;
}

.introHeading {
	font-size: 0.9rem;
}

h4 {
	color: white;
	margin: 1rem auto 0.1rem;
}

button {
	cursor: pointer;
	width: 40%;
	font-family: 'Teko', sans-serif;	
	padding: 0.2rem 2rem;
	background-color: white;
	color: black;
	font-size: 1.2rem;
	letter-spacing: 0.1rem;
	border: none;
	display: block;
	margin: 1.5rem auto;
	-webkit-transition: 1s all;
	transition: 1s all;
}

.selectBarberBtn {
	cursor: pointer;
	width: 90%;
	margin: auto;
	background-color: black;
	font-family: 'Teko', sans-serif;	
	color: white;	
	border: 1px solid white;
	position: absolute;
	bottom: 0;
}

.activeBarberBtn {
	cursor: pointer;
	width: 90%;
	margin: auto;
	background-color: #B48B4C;
	color: black;
	font-family: 'Teko', sans-serif;	
	border: 1px solid white;
	position: absolute;
	bottom: 0;	
}

.barberComponent img {
	width: 85%;
}

.summaryBox {
	width: 100%;
	margin: 2rem auto;
}

.summaryHeading {
	background-color: white;
	text-transform: uppercase;
	font-size: 0.9rem;
	letter-spacing: 0.2rem;
	color: black;
	text-align: center;
	display: block;
	width: 100%;
	padding: 0.6rem 1rem;
}

.summaryBreakdown {
	display: flex;
	width: 100%;
	margin: 0.5rem auto;
}

.summaryDetail {
	width: 30%;
	margin: 0 1rem;
	padding-top: 0.5rem;
	text-transform: uppercase;
}

.barberComponent {
	width: 17%;
	display: flex;
	height: 300px;
	position: relative;
}

.barberImg {
	width: 60%;
	overflow: hidden;
}

.barberInfo {
	width: 35%;
	text-align: left;
	position: relative;
}

.barberBtn {
	width: 100%;
	background-color: black;
	color: white;
	border: 1px solid white;
	font-size: 1rem;
}

.selectBarber {
	margin: auto;
	display: flex;
	position: absolute;
	width: 600%;
	height: 450px;
	overflow: hidden;
	-webkit-transition: 1.5s all;
	transition: 1.5s all;
}

.revealResponsiveBarber {
	-webkit-animation-name: revealBarber;
	        animation-name: revealBarber;
	-webkit-animation-delay: 1s;
	        animation-delay: 1s;
	-webkit-animation-duration: 1s;
	        animation-duration: 1s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

.returnToBarbers {
	-webkit-animation-name: returnToBarbers;
	        animation-name: returnToBarbers;
	-webkit-animation-duration: 1s;
	        animation-duration: 1s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;	
}

.firstSlide {
	left: 0;
}

.secondSlide {
	left: -100%;
}

.thirdSlide {
	left: -200%;
}

.fourthSlide {
	left: -300%;
}

.fifthSlide {
	left: -400%;
}

.sixthSlide {
	left: -500%;
}

.boldTag {
	display: inline-block;
	font-weight: bold;
}

.selectedBarber {
	background-color: white;
	color: black;
}

.toggleBarbers {
	color: white;
	display: flex;
	width: 100%;
	justify-content: center;
	margin: 2rem auto;
	border-top: 1px solid white;
	padding-top: 1rem;
	position: absolute;
	bottom: 15px;
	left: 0;
	-webkit-transition: 1.5s all;
	transition: 1.5s all;
}

.chooseBarber {
	width: 10%;
	display: inline-block;
	padding: 0;
	color: white;
	margin: auto 0.5rem;
	background-color: black;
	border: none;
	font-size: 1rem;
}

.activeBarber {
	width: 10%;
	display: inline-block;
	padding: 0;
	color: orange;
	margin: auto 0.5rem;
	background-color: black;
	border: none;
	font-size: 1rem;	
}

select {
	text-align: center;
}

.mobileBarberSection {
	width: 100%;
	background-color: black;
	color: white;
	overflow: hidden;
	-webkit-transition: 1.5s all;
	transition: 1.5s all;
	display: flex;
	text-align: center;
	position: relative;
	height: 900px;
}

.mobileBarbersContainer {
	width: 200%;
	background-color: black;
	position:absolute;
	display: flex;
	left: 0;
	-webkit-transition: 2s all;
	transition: 2s all;
}

.responsiveBarberOption {
	width: 90%;
	margin: 1rem auto;
	font-size: 0.7rem;
}

.responsiveBarberOption button {
	width: 80%;
	font-family: 'Teko', sans-serif;	
	padding: 0.2rem 2rem;
	background-color: white;
	color: black;
	font-size: 1.2rem;
	letter-spacing: 0.1rem;
	border: none;
	display: block;
	margin: 1rem auto;
	-webkit-transition: 1s all;
	transition: 1s all;	
}

.responsiveBarberOption img {
	width: 80%;
	margin: auto;
}

.backToBarbers {
	color: orange;
	font-size: 1.3rem;
}

.responsiveSummaryHeading {
	width: 85%;
	margin: 1rem auto;
	font-size: 0.8rem;
	display: block;
	padding: 0.5rem;
	color: black;
	background-color: lightgray;
}

.responsiveFormLabel {
	display: block;
	text-align: center;
	width: 100%;
	margin: 0.5rem auto;
}

.responsiveFormField {
	width: 80%;
	margin: 0.2rem auto;
	padding: 0.3rem 1rem;
	border-radius: 10px;
}

.mobileBarberPick {
	width: 50%;

}

.mobileBarberProfile {
	width: 50%;
}

.barberFlex {
	width: 90%;
	margin: 0.5rem auto;
	display: flex;
	justify-content: space-around;
}

.barberTemplate {
	width: 50%;
	margin: 0.5rem;
	position: relative;
}

.barberFlex div img {
	width: 100%;
}

.barberProfileNameTag {
	width: 100%;
	display: block;
	text-align: center;
	font-weight: bold;
	font-size: 0.7rem;
	letter-spacing: 0.1em;
}

.barberProfileSkillsTag {
	width: 100%;
	display: block;
	text-align: center;
	font-size: 0.6rem;	
}

.activeBarber {
	width: 10%;
	display: inline-block;
	padding: 0;
	color: orange;
	margin: auto 0.5rem;
	background-color: black;
	border: none;
	font-size: 1rem;	
}

.mobileBarbers {
	display: none;
}

@-webkit-keyframes slideDown {
    from {top: 0px;}
    to {top: -500px;}
}

@keyframes slideDown {
    from {top: 0px;}
    to {top: -500px;}
}

@-webkit-keyframes slideDownTwo {
    from {top: -500px;}
    to {top: -1000px;}
}

@keyframes slideDownTwo {
    from {top: -500px;}
    to {top: -1000px;}
}

@-webkit-keyframes slideDownThree {
    from {top: -1000px;}
    to {top: -1500px;}
}

@keyframes slideDownThree {
    from {top: -1000px;}
    to {top: -1500px;}
}

@-webkit-keyframes slideDownFour {
    from {top: -1500px;}
    to {top: -2000px;}
}

@keyframes slideDownFour {
    from {top: -1500px;}
    to {top: -2000px;}
}

@-webkit-keyframes revealBarber {
	from {left: 0;}
	to {left: -100%;}
}

@keyframes revealBarber {
	from {left: 0;}
	to {left: -100%;}
}

@-webkit-keyframes returnToBarbers {
	from {left: -100%;}
	to {left: 0%;}
}

@keyframes returnToBarbers {
	from {left: -100%;}
	to {left: 0%;}
}

@media only screen and (max-width: 1140px) {
 .bookerServiceContainer {
 	width: 75%;
 }	
}


@media only screen and (max-width: 912px) {
 .bookerServiceContainer {
 	width: 85%;
 }	
}


@media only screen and (max-width: 812px) { M
 .bookerServiceContainer {
 	width: 90%;
 }	

.barberImg {
	width: 50%;
	overflow: hidden;
}

.barberInfo {
	width: 50%;
	text-align: left;
	position: relative;
	padding: 0 1rem 0 0;
}

.selectBarberBtn {
	cursor: pointer;
	width: 90%;
	margin: auto;
	background-color: black;
	font-family: 'Teko', sans-serif;	
	color: white;	
	border: 1px solid white;
	position: absolute;
	bottom: 32px;
}

}

@media only screen and (max-width: 700px) {
.responsiveAppointment {
	width: 80%;
	margin: 5rem auto;
	background-color: black;
	color: white;
	padding: 1rem;
	text-align: center;
	display: block;
}

.bookerServiceContainer {
	display: none;
}

}

@media only screen and (max-width: 615px) {
.mobileBarberSection {
	width: 100%;
	background-color: black;
	color: white;
	overflow: hidden;
	-webkit-transition: 1.5s all;
	transition: 1.5s all;
	display: flex;
	text-align: center;
	position: relative;
	height: 800px;
}

}

@media only screen and (max-width: 538px) {
.mobileBarberSection {
	width: 100%;
	background-color: black;
	color: white;
	overflow: hidden;
	-webkit-transition: 1.5s all;
	transition: 1.5s all;
	display: flex;
	text-align: center;
	position: relative;
	height: 700px;
}

}


@media only screen and (max-width: 444px) {
.mobileBarberSection {
	width: 100%;
	background-color: black;
	color: white;
	overflow: hidden;
	-webkit-transition: 1.5s all;
	transition: 1.5s all;
	display: flex;
	text-align: center;
	position: relative;
	height: 600px;
}

}

@media only screen and (max-width: 358px) {
.mobileBarberSection {
	width: 100%;
	background-color: black;
	color: white;
	overflow: hidden;
	-webkit-transition: 1.5s all;
	transition: 1.5s all;
	display: flex;
	text-align: center;
	position: relative;
	height: 500px;
}

}
.staffTemplate {
	padding-top: 3rem;
	background-color: black;
	color: lightgray;
	padding-bottom: 2rem;
}


.staffTemplate h1 {
	width: 100%;
	display: block;
	text-align: center;
	margin: 1.5rem auto;
}

.staffProfile {
	display: flex;
	width: 75%;
	margin: auto;
	align-items: center;
	border: 0.2px solid #161616;
	padding: 2rem;
}

.staffImg {
	width: 50%;
}

img {
	width: 70%;
	display: block;
	margin: auto;
}

.staffDetails {
	width: 50%;
	line-height: 20px;
	padding-left: 1rem;
}

h2 {
	margin-bottom: 1.5rem;
}

.swapOrder {
	padding-right: 1rem;
}

@media only screen and (max-width: 964px) {
.staffProfile {
	display: flex;
	width: 85%;
	margin: auto;
	align-items: center;
	border: 0.2px solid #161616;
	padding: 2rem;
}	
}

@media only screen and (max-width: 838px) {
.staffProfile {
	display: flex;
	flex-direction: column;
	width: 85%;
	margin: auto;
	border: 0.2px solid #161616;
	padding: 2rem;
	text-align: center;
}

.swapOrder {
	order: 2;
}

.staffImg {
	width: 80%;
}

img {
	width: 70%;
	display: block;
	margin: auto;
}

.staffDetails {
	width: 80%;
	line-height: 20px;
	display: block;	
}

h2 {
	text-align: center;
	margin-bottom: 1rem;
}

}

@media only screen and (max-width: 614px) {
.staffProfile {
	display: flex;
	flex-direction: column;
	width: 85%;
	margin: auto;
	border: 0.2px solid #161616;
	padding: 0.5rem;
}

.swapOrder {
	order: 2;
}

.staffImg {
	width: 80%;
}

img {
	width: 85%;
	display: block;
	margin: auto;
}

.staffDetails {
	width: 80%;
	line-height: 20px;
}

h2 {
	text-align: center;
	margin-bottom: 1rem;
}

}
.InsideTheShopTemplate {
	background-color: black;
	color: white;
	padding-top: 3rem;
	min-height: 100vh;
	padding-bottom: 2rem;
}

.insideShopDiv {
	width: 100%;
	padding: 1rem;
}

.shopImg {
	width: 100%;
}

.shopDetails {
	width: 100%;
	line-height: 20px;
}

img {
	width: 100%;
}

.InsideTheShopTemplate h1, .InsideTheShopTemplate h2 {
	text-align: center;
	margin: 1rem auto;
}

.sideBySideTemplate {
	display: flex;
	width: 80%;
	margin: auto;
}

@media only screen and (max-width: 763px) {
.sideBySideTemplate {
	display: flex;
	flex-direction: column;
}

h2 {
	border-bottom: 1px white solid;
	padding-bottom: 0.5rem;
}

}

@media only screen and (max-width: 457px) {
.sideBySideTemplate {
	display: flex;
	flex-direction: column;
	width: 90%;
}

h2 {
	border-bottom: 1px white solid;
	padding-bottom: 0.5rem;
}

}
.paymentTemplate {
	width: 60%;
	margin: auto;
	padding-top: 3rem;
}

.formLabel {
	display: block;
	text-align: center;
	width: 40%;
	text-align: center;
	margin: 1rem auto 0.5rem;
}

.paymentForm img {
	padding: 1rem 0;
	width: 60%;
	margin: auto;
	border-top: 1px solid gray;
	border-bottom: 1px solid gray;
}

.expDate {
	display: flex;
	width: 100%;
	margin: 1rem auto;
	justify-content: center;	
}

.paymentForm {
	border: solid gray 1px;
	background-color: black;
	color: white;
	border: 1px solid white;
	box-shadow: 3px 4px 4px 5px gray;
}

.paymentForm h1 {
	margin: 1rem auto;
	width: 100%;
	display: block;
	text-align: center;
}

.paymentForm input {
	padding: 0.3rem 1.4rem;
	width: 40%;
	display: block;
	margin: auto;
}

.paymentForm input:focus {
	border: solid 2px #B48B4C;
}

.expDate input {
	width: 15%;
	margin: auto 0.4rem;
}

@media only screen and (max-width: 997px) {
.paymentTemplate {
	width: 85%;
	margin: auto;
	padding-top: 3rem;
}

}

@media only screen and (max-width: 503px) {
.paymentForm input {
	padding: 0.3rem 1.4rem;
	width: 70%;
	display: block;
	margin: auto;
}
.expDate input {
	width: 25%;
	margin: auto 0.4rem;
}

.paymentComponentContainer button {
	width: 80%;
}

}
